export interface Plan {
  monthly: PlanDetails[];
  yearly: PlanDetails[];
}

export interface PlanDetails {
  id: string;
  type: string;
  desc: string;
  price: string;
  discount: null | string;
  features: string[];
}

export const PLANS = {
  monthly: [
    {
      id: "QUIiXhfithZ8TKM0dynK",
      type: "Free",
      desc: "Ideal for small interior design businesses or freelancers who are just starting out.",
      price: "0",
      discount: null,
      features: [
        "Add up to 20 leads per month",
        "Add up to 10 projects",
        "Generate up to 30 quotations per month",
        "Basic reports",
        "Add up to 2 team member",
      ],
    },
    {
      id: "pJ9OtFyX0U58z6rwv2ik",
      type: "Standard",
      desc: "Perfect for growing interior design companies looking for more advanced features and support.",
      price: "400",
      discount: null,
      features: [
        "Add up to 100 leads per month",
        "Unlimited projects",
        "Generate up to 150 quotations per month",
        "Basic reports & analytics",
        "Add up to 5 team members",
      ],
    },
    {
      id: "x7UtlERnf5vlPeEHBXPb",
      type: "Advanced",
      desc: "Designed for established interior design companies or agencies with high-volume projects and demanding clients.",
      price: "900",
      discount: null,
      features: [
        "Unlimited leads",
        "Unlimited projects",
        "Unlimited Quotation generations",
        "Advanced reports & analytics",
        "Add unlimited team members",
      ],
    },
  ],
  yearly: [
    {
      id: "3omgEg759SxMKnrdlrFb",
      type: "Free",
      desc: "Ideal for small interior design businesses or freelancers who are just starting out.",
      price: "0",
      discount: null,
      features: [
        "Add up to 20 leads per month",
        "Add up to 10 projects",
        "Generate up to 30 quotations per month",
        "Basic reports",
        "Add up to 2 team member",
      ],
    },
    {
      id: "D5mMOxCY44ihxWgU2fLk",
      type: "Standard",
      desc: "Perfect for growing interior design companies looking for more advanced features and support.",
      price: "4,800",
      discount: "3,360",
      features: [
        "Add up to 100 leads per month",
        "Unlimited projects",
        "Generate up to 150 quotations per month",
        "Basic reports & analytics",
        "Add up to 5 team members",
      ],
    },
    {
      id: "UeWBeGIKyC6jVdp7VljJ",
      type: "Advanced",
      desc: "Designed for established interior design companies or agencies with high-volume projects and demanding clients.",
      price: "10,800",
      discount: "7,560",
      features: [
        "Unlimited leads",
        "Unlimited projects",
        "Unlimited Quotation generations",
        "Advanced reports & analytics",
        "Add unlimited team members",
      ],
    },
  ],
} as Plan;

export const LEAD_STATUS = [
  "Initial Discussion",
  "Proposal Sent",
  "Proposal Accepted",
  "Convert to Client",
  "On Hold",
  "Canceled",
];

export const AREA_UNITS = [
  "sqft",
  "sqyrd",
  "sqm",
  "acre",
  "bigha",
  "hectare",
  "marla",
  "kanal",
  "biswa1",
  "biswa2",
  "ground",
  "aankadam",
  "rood",
  "chatak",
  "kottah",
  "marla",
  "cent",
  "perch",
  "guntha",
  "are",
  "katha",
  "gaj",
  "killa",
  "kuncham",
] as const;

export const BHK_LIST = [
  "1 BHK",
  "2 BHK",
  "3 BHK",
  "4 BHK",
  "5 BHK",
  "6 BHK",
  "1 RK", // 1 Room Kitchen (studio apartment)
  "2 RK", // 2 Room Kitchen
  "1.5 BHK", // 1 Bedroom, Hall, Kitchen + Study/Small Room
  "2.5 BHK", // 2 Bedroom, Hall, Kitchen + Study/Small Room
  "3.5 BHK", // 3 Bedroom, Hall, Kitchen + Study/Small Room
  "4.5 BHK", // 4 Bedroom, Hall, Kitchen + Study/Small Room
] as const;

export const PROJECT_STATUS = [
  "Initial Discussion",
  "Proposal Sent",
  "Proposal Accepted",
  "In Progress",
  "On Hold",
  "Pending Approval",
  "Completed",
  "Canceled",
  "Awaiting Payment",
  "Design Phase",
  "Material Procurement",
  "Under Construction",
  "Quality Check",
  "Feedback Pending",
  "Revision Required",
  "Ready for Review",
] as const;

export const PROJECT_TYPE = [
  "Airport",
  "Apartment Complex",
  "Art Gallery",
  "Bank",
  "Cafe",
  "Commercial Complex",
  "Community Center",
  "Condominium",
  "Convention Center",
  "Cultural Center",
  "Data Center",
  "Educational Institution",
  "Entertainment Venue",
  "Fitness Center",
  "Gated Community",
  "Government Building",
  "Green Building",
  "Hospital",
  "Hotel",
  "Independent House",
  "Industrial Facility",
  "Library",
  "Mixed-Use Development",
  "Museum",
  "Office Space",
  "Other",
  "Park",
  "Public Space",
  "Religious Institution",
  "Renovation",
  "Residential Complex",
  "Restaurant",
  "Retail Store",
  "Restoration",
  "Shopping Mall",
  "Smart Building",
  "Sustainable Building",
  "Technology Hub",
  "Theater",
  "Townhouse",
  "Transportation Hub",
  "Villa",
  "Warehouse",
  "Others",
] as const;

export const SOURCE_TYPE = [
  "Website",
  "Referral",
  "WhatsApp",
  "Facebook",
  "Instagram",
  "LinkedIn",
  "Direct",
] as const;

export function serializeObject(obj: any): any {
  // If the object is undefined, return null
  if (obj === undefined) {
    return null;
  }
  // If the object is not of type 'object' or is null, return the object as is
  if (
    typeof obj !== "object" ||
    obj === null ||
    obj === undefined ||
    obj === ""
  ) {
    return obj;
  }
  // If the object is an array, recursively serialize each element
  if (Array.isArray(obj)) {
    return obj.map((item) => serializeObject(item));
  }
  // If the object is a plain object, recursively serialize each property
  const result: { [key: string]: any } = {};
  // Iterate over each property of the object
  for (const key in obj) {
    // Check if the property is directly defined on the object (not inherited)
    if (Object.hasOwnProperty.call(obj, key)) {
      // Recursively serialize each property value
      result[key] = serializeObject(obj[key]);
    }
  }
  // Return the resulting serialized object
  return result;
}

export const currency = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
});

export const dateFormat = new Intl.DateTimeFormat("en-IN", {
  year: "numeric",
  month: "short",
  day: "numeric",
});

export const dateTimeFormat = new Intl.DateTimeFormat("en-IN", {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
});
