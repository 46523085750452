import { FirebaseError } from "firebase/app";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { db } from "../firebase";
import { useAppDispatch } from "../store/hooks";
import { setShowBackdrop, setShowSnackbar } from "../store/ui.store";
import useError from "./useError";

export interface SignupForm {
  companyName: string;
  email: string;
  phoneNumber: string;
  password: string;
  cnfPassword: string;
  planId: string;
}

const useSignup = () => {
  const dispatch = useAppDispatch();
  const handleError = useError();
  const [error, setError] = useState<Record<string, string>>();
  const [searchParams, setSearchParams] = useSearchParams();
  const signupStep = Number(searchParams.get("step"));

  const id = searchParams.get("id");

  const updateSearchParams = (params: Record<string, string>) => {
    const newSearchParams = new URLSearchParams(searchParams);
    Object.entries(params).forEach(([key, value]) => {
      newSearchParams.set(key, value);
    });
    setSearchParams(newSearchParams);
  };

  const checkUniqueFields = async (
    data: string,
    field: "email" | "phoneNumber"
  ) => {
    const q = query(collection(db, "users"), where(field, "==", data));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.length === 0;
  };

  const handleDocUpdate = async (data: Partial<SignupForm>) => {
    setError(undefined);
    let isUnique = false;

    if (data.email && signupStep === 2) {
      isUnique = await checkUniqueFields(data.email, "email");
    }

    if (data.phoneNumber && signupStep === 3) {
      isUnique = await checkUniqueFields(data.phoneNumber, "phoneNumber");
    }
    if (!isUnique && [2, 3].includes(signupStep)) {
      setError({
        [signupStep === 2 ? "email" : "phoneNumber"]:
          signupStep === 2
            ? "Email already exists!"
            : "Phone Number already exists!",
      });
      return;
    }
    if (id && signupStep !== 1) {
      delete data.planId;
      await updateDoc(doc(db, "users", id), {
        ...data,
        updatedAt: serverTimestamp(),
      });
      updateSearchParams({ step: `${signupStep + 1}` });
    } else {
      const res = await addDoc(collection(db, "users"), {
        ...data,
        registered: false,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      updateSearchParams({ id: `${res.id}`, step: "2" });
    }
  };

  const updateUser = async (data: Partial<SignupForm>) => {
    dispatch(setShowBackdrop(true));
    try {
      await handleDocUpdate(data);
    } catch (error) {
      if (error instanceof FirebaseError && error.code === "not-found") {
        try {
          await handleDocUpdate(data);
        } catch (nestedError) {
          handleError(nestedError);
        }
      }
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const createTenant = async (password: string, docId?: string) => {
    if (!docId) dispatch(setShowBackdrop(true));
    try {
      const url =
        process.env.NODE_ENV === "development"
          ? "http://127.0.0.1:5001/decorflow-40fd9/us-central1/createTenant"
          : "https://createtenant-qptp7l3cfq-uc.a.run.app";
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ password, docId: docId ?? id }),
      });
      if (response.status === 200) {
        if (!docId) updateSearchParams({ step: `${signupStep + 1}` });
        return true;
      } else {
        const res = await response.json();
        console.error("createTenant", res.message);
        dispatch(
          setShowSnackbar({ open: true, msg: res.message, type: "error" })
        );
        return false;
      }
    } catch (error) {
      handleError(error, "createTenant");
      return false;
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  return { updateUser, createTenant, error };
};

export default useSignup;
