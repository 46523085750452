import {
  addDoc,
  collection,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { IQuotation, setQuotations } from "../store/data.store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setShowBackdrop } from "../store/ui.store";
import useError from "./useError";
import { db } from "../firebase";
import { serializeObject } from "../utils/constants";
import { useCallback } from "react";

const useQuotation = () => {
  const dispatch = useAppDispatch();
  const handleError = useError();

  const { user, quotations } = useAppSelector((state) => state.data);

  const saveAsDraft = async (data: IQuotation) => {
    dispatch(setShowBackdrop(true));
    try {
      const path = `/tenants/${user?.tenantId}/quotations`;

      const result = await addDoc(collection(db, path), {
        ...serializeObject(data),
        status: "Draft",
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      dispatch(setQuotations([...quotations, { ...data, docId: result.id }]));
      return true;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const getQuotations = useCallback(async () => {
    if (quotations.length !== 0) return;

    dispatch(setShowBackdrop(true));
    try {
      const path = `/tenants/${user?.tenantId}/quotations`;

      const q = query(collection(db, path), orderBy("createdAt", "asc"));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map(
        (e) => ({ ...e.data(), docId: e.id } as IQuotation)
      );
      dispatch(setQuotations(data));
    } catch (error) {
      handleError(error);
    } finally {
      dispatch(setShowBackdrop(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, quotations.length, user?.tenantId]);

  return { saveAsDraft, getQuotations };
};

export default useQuotation;
