const LIST = [
  "The exact price of your project will depend on measurements, scope of work, and changes in designs/materials/finishes. Expect the quote to fluctuate by approximately 5-10% based on revisions.",
  "To proceed with the development of measurement-based designs, a non-refundable advance payment of 10% of the estimate is required.",
  "The minimum order value is INR 1,50,000. Orders below this value will not be accepted.",
  "Initial estimates may not include civil, plumbing, or gas-piping work. The final quote will depend on site conditions.",
  "Back panels will be constructed of the same cabinet core material for all products.",
  "All lofts will be non-soft close by default.",
  "The final payment must be made before the material dispatches from the factory and not before installation.",
];
const TermsAndConditions = () => {
  return (
    <div className="pagebreak">
      <h3 className="text-xl font-semibold text-center my-4">
        Terms and Conditions
      </h3>
      <ul className="list-decimal list-inside">
        {LIST.map((item) => (
          <li className="mt-3" key={item}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TermsAndConditions;
