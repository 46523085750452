export const MOCK_QUOTATION_DATA = {
  titles: [
    {
      title: "Others",
      fields: [
        {
          Product: "Shoe Rack",
          Dimensions: "4'0\" x 4'0\"",
          Unit: "SFT",
          Area: "16",
          Rate: "1150",
          Amount: "18400",
        },
        {
          Product: "Utility Area",
          Dimensions: "4'4\" x 4'0\"",
          Unit: "SFT",
          Area: "17.6",
          Rate: "1150",
          Amount: "20240",
        },
      ],
    },
    {
      title: "Living and Dining Area",
      fields: [
        {
          Product: "TV Unit",
          Dimensions: "8'0\" x 8'0\"",
          Unit: "SFT",
          Area: "64",
          Rate: "1150",
          Amount: "73600",
        },
        {
          Product: "PVC Wall Trims for Sofa Backside",
          Dimensions: "",
          Unit: "",
          Area: "",
          Rate: "",
          Amount: "15000",
        },
        {
          Product: "Partition with Glass",
          Dimensions: "3'0\" x 8'0\"",
          Unit: "SFT",
          Area: "24",
          Rate: "",
          Amount: "30000",
        },
        {
          Product: "Crockery Unit with Profile Glass",
          Dimensions: "",
          Unit: "",
          Area: "",
          Rate: "",
          Amount: "50000",
        },
        {
          Product: "Pooja Unit",
          Dimensions: "2'4\" x 8'0\"",
          Unit: "SFT",
          Area: "19.2",
          Rate: "1150",
          Amount: "22080",
        },
        {
          Product: "Vanity Unit with Mirror",
          Dimensions: "",
          Unit: "",
          Area: "",
          Rate: "",
          Amount: "20000",
        },
      ],
    },
    {
      title: "Kitchen",
      fields: [
        {
          Product: "Kitchen Base Unit",
          Dimensions: "10'6\" x 6'7\"",
          Unit: "SFT",
          Area: "71.02",
          Rate: "1150",
          Amount: "81673",
        },
        {
          Product: "Loft",
          Dimensions: "10'6\" x 6'7\"",
          Unit: "SFT",
          Area: "71.02",
          Rate: "750",
          Amount: "53265",
        },
        {
          Product: "Wall Cabinets",
          Dimensions: "4'2\" x 2'0\"",
          Unit: "SFT",
          Area: "8.4",
          Rate: "1150",
          Amount: "10000",
        },
        {
          Product: "Pantry Unit",
          Dimensions: "",
          Unit: "",
          Area: "",
          Rate: "",
          Amount: "20000",
        },
        {
          Product: "Tandem Baskets and Bottle Pullout with Cutlery",
          Dimensions: "",
          Unit: "",
          Area: "",
          Rate: "",
          Amount: "20000",
        },
      ],
    },
    {
      title: "MBR",
      fields: [
        {
          Product: "Sliding Wardrobe",
          Dimensions: "8'0\" x 8'0\"",
          Unit: "SFT",
          Area: "64",
          Rate: "1150",
          Amount: "73600",
        },
        {
          Product: "Loft",
          Dimensions: "8'0\" x 2'0\"",
          Unit: "SFT",
          Area: "16",
          Rate: "750",
          Amount: "12000",
        },
        {
          Product: "Dressing Unit",
          Dimensions: "",
          Unit: "",
          Area: "",
          Rate: "",
          Amount: "15000",
        },
        {
          Product: "Sofa Cushion with Storage",
          Dimensions: "",
          Unit: "",
          Area: "",
          Rate: "",
          Amount: "20000",
        },
        {
          Product: "Glass Partition in Bathroom",
          Dimensions: "",
          Unit: "",
          Area: "",
          Rate: "",
          Amount: "15000",
        },
        {
          Product: "Wall Paper for One Wall",
          Dimensions: "",
          Unit: "",
          Area: "",
          Rate: "",
          Amount: "7000",
        },
      ],
    },
    {
      title: "CBR",
      fields: [
        {
          Product: "Wardrobe",
          Dimensions: "8'0\" x 8'0\"",
          Unit: "SFT",
          Area: "64",
          Rate: "1150",
          Amount: "73600",
        },
      ],
    },
    {
      title: "GBR",
      fields: [
        {
          Product: "Wardrobe",
          Dimensions: "6'0\" x 8'0\"",
          Unit: "SFT",
          Area: "48",
          Rate: "1150",
          Amount: "55200",
        },
      ],
    },
  ],
};
