import { Box, Typography } from "@mui/material";
import { useAppSelector } from "../../store/hooks";
import { currency, dateFormat } from "../../utils/constants";

const GenerateQuotation = () => {
  const quotation = useAppSelector((state) => state.data.quotation);

  const getAmountAsNumber = (amount: string): number => {
    return parseFloat(amount);
  };

  const calculateTotal = () => {
    return !quotation?.titles
      ? 0
      : quotation.titles.reduce((acc, title) => {
          return (
            acc +
            title.fields.reduce(
              (acc2, current) => acc2 + getAmountAsNumber(current.Amount),
              0
            )
          );
        }, 0);
  };

  return (
    <Box className="print:block print-style">
      <div className="hidden print:block watermark">
        <img
          className="min-w-36 min-h-36"
          alt="logo"
          src="https://www.livspace.com/_nuxt/img/livspace.458227b.svg"
        />
      </div>
      <div className="flex justify-between my-5">
        <img
          className="h-11"
          alt="logo"
          src="https://www.livspace.com/_nuxt/img/livspace.458227b.svg"
        />
        <div>
          <div className="flex gap-4">
            <Typography variant="body2" component="div">
              Quotation#
            </Typography>
            <Typography
              variant="body2"
              component="div"
              sx={{ fontWeight: "medium", textAlign: "center" }}
            >
              001
            </Typography>
          </div>
          <div className="flex gap-4">
            <Typography variant="body2" component="div">
              Quotation Date
            </Typography>
            <Typography
              variant="body2"
              component="div"
              sx={{ fontWeight: "medium", textAlign: "center" }}
            >
              {dateFormat.format(new Date())}
            </Typography>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 my-10 text-sm">
        <div>
          <div className="text-primaryDark font-semibold mb-3">
            Quotation to
          </div>
          <div className="font-semibold">Naveen Aluri</div>
          <div>
            Y-103, Suraj Vidhi Homes, Hyderabad,
            <br /> Telangana - 500008
          </div>
          <div>Mobile Number: +91 8686914316</div>
        </div>
        <div>
          <div className="text-primaryDark font-semibold mb-3">
            Project Details
          </div>
          <div>
            <strong>Property Name:</strong> Muppa's Melody
          </div>
          <div>
            <strong>Property Type:</strong> 2.5 BHK
          </div>
          <div>
            <strong>Build-up Area:</strong> 1400 sft
          </div>
          <div>
            <strong>Address:</strong> Tellapur, Muncipality Area, Osman Nagar
            Rd, beside Muppa's Indraprastha, Osman Nagar, Hyderabad, Telangana
            502032
          </div>
        </div>
      </div>
      <div className="border">
        {quotation?.titles.map((title, index) => (
          <div key={index} className="mb-4">
            <div className="w-full bg-primary text-white mb-2 text-sm font-semibold px-4 py-2">
              <h2>{title.title}</h2>
            </div>
            <table className="w-full table-fixed">
              <thead className="border-b">
                <tr>
                  {Object.keys(title.fields[0]).map((fieldName, i) => (
                    <th
                      className={`font-semibold text-sm px-4 py-2 ${
                        i === 0 ? "text-left" : "text-center"
                      }`}
                      key={i}
                    >
                      {fieldName}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {title.fields.map((field, i) => {
                  return (
                    <tr key={i} className="border-b">
                      {Object.values(field).map((value, j) => (
                        <td
                          className={`font-medium text-sm px-4 py-2 ${
                            j === 0 ? "text-left" : "text-center"
                          }`}
                          key={j}
                        >
                          {value}
                        </td>
                      ))}
                    </tr>
                  );
                })}
                <tr className="">
                  <td className="font-bold text-base px-4 py-2">Total</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td
                    colSpan={2}
                    className="font-bold text-base text-right px-4 py-2"
                  >
                    {currency.format(
                      title.fields.reduce((acc, current) => {
                        return acc + getAmountAsNumber(current.Amount);
                      }, 0)
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </div>
      <div className="border mt-10">
        <div className="w-full bg-primary text-white mb-2 font-semibold text-xl px-4 py-2">
          Summary
        </div>
        {quotation?.titles.map((title, index) => (
          <div key={index} className="mb-4">
            <table className="w-full table-auto">
              <tbody>
                <td className="font-bold text-base px-4 py-2">{title.title}</td>
                <td className="font-bold text-base text-right px-4 py-2">
                  {currency.format(
                    title.fields.reduce((acc, current) => {
                      return acc + getAmountAsNumber(current.Amount);
                    }, 0)
                  )}
                </td>
              </tbody>
            </table>
          </div>
        ))}
      </div>
      <div className="flex justify-between p-4 font-bold text-xl">
        <div>Total Quotation</div>
        <div>{currency.format(calculateTotal())}</div>
      </div>
    </Box>
  );
};

export default GenerateQuotation;
