import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useProfile from "../../hooks/useProfile";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import AddTeamMemberDialog from "../../components/AddTeamMemberDialog";
import { setDeleteDialog } from "../../store/ui.store";

const TeamsTab = () => {
  const dispatch = useAppDispatch();
  const { getUsers, removeMember } = useProfile();
  const { users, profile } = useAppSelector((state) => state.data);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (users.length === 0) {
      getUsers();
    }
  }, [getUsers, users.length]);

  return (
    <Box sx={{ maxWidth: "42rem" }}>
      <AddTeamMemberDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
      <div className="flex items-center justify-between">
        <div>Manage your team members</div>
        <Button size="small" variant="contained" onClick={() => setOpen(true)}>
          Add Member
        </Button>
      </div>
      <div className="my-10">
        <List className="border rounded-md">
          {users.map((user) => (
            <ListItem key={user.uid} alignItems="flex-start">
              <ListItemAvatar>
                <Avatar
                  alt={`${user.firstName} ${user.lastName}`}
                  src={user.avatar?.url ?? ""}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  !user.firstName
                    ? `${user.email}`
                    : `${user.firstName} ${user.lastName}`
                }
                primaryTypographyProps={{
                  fontWeight: "600",
                }}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: "inline" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {user.role}
                    </Typography>{" "}
                    {user.uid === profile?.uid && (
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        (You)
                      </Typography>
                    )}
                  </React.Fragment>
                }
              />
              <div className="flex gap-2 items-center">
                <div className="font-semibold text-yellow-700">
                  {user.isAccepted ? "" : "Invited"}
                </div>
                {user.uid !== profile?.uid && profile?.role === "Admin" && (
                  <Button
                    size="small"
                    variant="text"
                    color="error"
                    onClick={() => {
                      dispatch(
                        setDeleteDialog({
                          show: true,
                          onDelete: async () => {
                            await removeMember(user.uid, user.email);
                            dispatch(setDeleteDialog({ show: false }));
                          },
                        })
                      );
                    }}
                  >
                    Remove
                  </Button>
                )}
              </div>
            </ListItem>
          ))}
        </List>
      </div>
    </Box>
  );
};

export default TeamsTab;
