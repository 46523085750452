import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { PROJECT_TYPE } from "../utils/constants";
import { Timestamp } from "firebase/firestore";
import { User } from "firebase/auth";

export interface Lead {
  docId?: string;
  name: string;
  mobile: string;
  email?: string;
  company?: string;
  source: string;
  status: string;
  type: "Lead" | "Client";
  address: Address;
}

export interface Address {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  pincode: string;
}

export interface Project {
  docId: string;
  name: string;
  type: string;
  area?: {
    units?: string;
    value?: number;
  };
  bhk?: string;
  additionalType?: string;
  status: string;
  clientBudget: string;
  startDate: string;
  endDate: string;
  notes: string;
  address: Address;
  leadId: string;
  attachments: Attachment[];
  createdAt: Timestamp;
}

export interface Attachment {
  title: string;
  type: string;
  url: string;
}

export interface IProject {
  docId: string;
  projectName: string;
  projectAddress: string;
  projectType: (typeof PROJECT_TYPE)[number];
  projectStatus: string;
  budget?: string;
  scope?: string;
  startDate?: Timestamp;
  endDate?: Timestamp;
  notes?: string;
  attachments?: {
    title: string;
    type: string;
    url: string;
  }[];
}

export interface Profile {
  uid: string;
  email: string;
  role: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  avatar: {
    path: string;
    type: string;
    url: string;
  };
  isAccepted: boolean;
}

export interface Company {
  companyName: string;
  planId: string;
  displayName: string;
  logo: {
    path: string;
    type: string;
    url: string;
  };
}

export interface IQuotation {
  docId?: string;
  status?: string;
  titles: Title[];
  lead: string;
  project: string;
}

export interface Title {
  title: string;
  fields: Field[];
}

export interface Field {
  [key: string]: any;
  // Product: string;
  // Dimensions: string;
  // Unit: string;
  // Area: string;
  // Rate: string;
  // Amount: string;
}

export interface DataState {
  leads: Lead[];
  clients: Lead[];
  projects: Project[];
  user?: User;
  profile?: Profile;
  company?: Company;
  users: Profile[];
  quotations: IQuotation[];
  quotation?: IQuotation;
  quotationFor?: {
    lead?: Lead;
    projectInfo?: Project[];
    project?: string;
  };
}

const initialState: DataState = {
  leads: [],
  clients: [],
  projects: [],
  users: [],
  quotations: [],
};

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setLeads(state, action: PayloadAction<DataState["leads"]>) {
      state.leads = action.payload;
    },
    setClients(state, action: PayloadAction<DataState["clients"]>) {
      state.clients = action.payload;
    },
    setProjects(state, action: PayloadAction<DataState["projects"]>) {
      state.projects = action.payload;
    },
    setUser(state, action: PayloadAction<DataState["user"]>) {
      state.user = action.payload;
    },
    setProfile(state, action: PayloadAction<DataState["profile"]>) {
      state.profile = action.payload;
    },
    setCompany(state, action: PayloadAction<DataState["company"]>) {
      state.company = action.payload;
    },
    setUsers(state, action: PayloadAction<DataState["users"]>) {
      state.users = action.payload;
    },
    setQuotations(state, action: PayloadAction<DataState["quotations"]>) {
      state.quotations = action.payload;
    },
    setQuotation(state, action: PayloadAction<DataState["quotation"]>) {
      state.quotation = action.payload;
    },
    setQuotationFor(state, action: PayloadAction<DataState["quotationFor"]>) {
      state.quotationFor = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLeads,
  setClients,
  setProjects,
  setUser,
  setProfile,
  setCompany,
  setUsers,
  setQuotations,
  setQuotation,
  setQuotationFor,
} = dataSlice.actions;

export default dataSlice.reducer;
