import { PermMedia } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import InputField from "../../components/inputField";
import useProfile from "../../hooks/useProfile";
import { useAppSelector } from "../../store/hooks";
import { useForm } from "react-hook-form";
import { Profile } from "../../store/data.store";

const GeneralTab = () => {
  const { updateLogo, updateProfile } = useProfile();
  const { profile, company } = useAppSelector((state) => state.data);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Profile>({
    defaultValues: {
      firstName: profile?.firstName ?? "",
      lastName: profile?.lastName ?? "",
    },
  });

  const [logo, setLogo] = useState<File | undefined>();

  const onSubmit = async (data: Profile) => {
    await updateProfile({ ...data });
  };

  return !profile ? null : (
    <Box>
      <div className="col-span-full mb-8">
        <label
          htmlFor="cover-photo"
          className="text-base font-semibold leading-7 text-gray-900"
        >
          Company Logo
        </label>
        {!company?.logo ? (
          <div className="mt-2 flex flex-col justify-center items-center rounded-lg border border-dashed border-gray-900/25 px-4 py-8">
            {!logo ? (
              <PermMedia
                sx={{
                  width: "80px",
                  height: "80px",
                }}
                className="mx-auto text-gray-300"
                aria-hidden="true"
              />
            ) : (
              <img
                src={URL.createObjectURL(logo) || ""}
                alt="Logo"
                className="h-20"
              />
            )}
            <div className="mt-4 text-sm leading-6 text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600"
              >
                <span>Upload a logo</span>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  accept="image/*"
                  className="sr-only"
                  onChange={(e) => {
                    console.log("AAAA", e.target.files);
                    setLogo(e.target.files?.[0]);
                  }}
                />
              </label>
            </div>
            <p className="text-xs leading-5 text-gray-600">
              PNG, JPEG, JPG, SVG up to 5MB
            </p>
            {logo && (
              <div className="flex gap-5 mt-5">
                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    setLogo(undefined);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    updateLogo(logo);
                  }}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        ) : (
          <img
            className="h-52"
            src={company?.logo.url}
            alt={company.companyName}
          />
        )}
      </div>
      <div className="flex gap-5 max-w-2xl">
        <div className="w-full">
          <label
            htmlFor="company-name"
            className="text-base font-semibold leading-7 text-gray-900"
          >
            Company Name
          </label>
          <InputField
            id="company-name"
            label=""
            value={company?.companyName || ""}
            disabled
            inputProps={{
              readOnly: true,
            }}
            fullWidth
            required
            variant="outlined"
          />
        </div>
        <div className="w-full">
          <label
            htmlFor="email"
            className="text-base font-semibold leading-7 text-gray-900"
          >
            Email
          </label>
          <InputField
            id="email"
            label=""
            value={profile?.email || ""}
            disabled
            inputProps={{
              readOnly: true,
            }}
            fullWidth
            required
            variant="outlined"
          />
        </div>
      </div>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-5 my-10 max-w-2xl">
          <InputField
            id="firstName"
            {...register("firstName", { required: true })}
            label="First Name"
            fullWidth
            value={profile?.firstName}
            required
            variant="outlined"
            error={!!errors.firstName}
          />
          <InputField
            id="lastName"
            {...register("lastName", { required: true })}
            label="Last Name"
            fullWidth
            required
            value={profile?.lastName}
            variant="outlined"
            error={!!errors.lastName}
          />
        </div>
        <Button type="submit" size="small" variant="contained">
          Save
        </Button>
      </form>
    </Box>
  );
};

export default GeneralTab;
