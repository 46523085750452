import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { useCallback } from "react";
import { db } from "../firebase";
import { Project, setProjects } from "../store/data.store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setDeleteDialog, setShowBackdrop } from "../store/ui.store";
import useError from "./useError";

const useProjects = () => {
  const dispatch = useAppDispatch();
  const handleError = useError();

  const { projects, user } = useAppSelector((state) => state.data);

  const addProject = async (data: Project) => {
    dispatch(setShowBackdrop(true));
    try {
      const path = `/tenants/${user?.tenantId}/projects`;

      const result = await addDoc(collection(db, path), {
        ...data,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      dispatch(setProjects([...projects, { ...data, docId: result.id }]));
      return true;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const updateProject = async (docId: string, data: Partial<Project>) => {
    dispatch(setShowBackdrop(true));
    try {
      await updateDoc(doc(db, `/tenants/${user?.tenantId}/projects/${docId}`), {
        ...data,
        updatedAt: serverTimestamp(),
      });

      const updated = projects.map((e) =>
        e.docId === docId ? { ...e, ...data } : e
      );
      dispatch(setProjects(updated));

      return true;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const getProjects = useCallback(async () => {
    if (projects.length !== 0) return;

    dispatch(setShowBackdrop(true));
    try {
      const path = `/tenants/${user?.tenantId}/projects`;

      const q = query(collection(db, path), orderBy("createdAt", "asc"));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map(
        (e) => ({ ...e.data(), docId: e.id } as Project)
      );
      dispatch(setProjects(data));
    } catch (error) {
      handleError(error);
    } finally {
      dispatch(setShowBackdrop(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, projects.length, user?.tenantId]);

  const getLeadProjects = useCallback(
    async (docId: string) => {
      dispatch(setShowBackdrop(true));
      try {
        const path = `/tenants/${user?.tenantId}/projects`;
        const q = query(
          collection(db, path),
          where("leadId", "==", docId),
          orderBy("createdAt", "asc")
        );
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map(
          (e) => ({ ...e.data(), docId: e.id } as Project)
        );
        return data;
      } catch (error) {
        handleError(error);
        console.error(error);
        return [];
      } finally {
        dispatch(setShowBackdrop(false));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, user?.tenantId]
  );

  const deleteProject = async (docId: string) => {
    dispatch(setShowBackdrop(true));
    try {
      await deleteDoc(doc(db, `/tenants/${user?.tenantId}/projects/${docId}`));

      dispatch(setDeleteDialog({ show: false }));
      dispatch(setProjects(projects.filter((e) => e.docId !== docId)));
    } catch (error) {
      handleError(error);
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  return {
    addProject,
    updateProject,
    getProjects,
    deleteProject,
    getLeadProjects,
  };
};
export default useProjects;
