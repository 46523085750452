import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useAppDispatch } from "../../store/hooks";
import { setDeleteDialog } from "../../store/ui.store";

interface IDialogProps {
  open: boolean;
  onDelete?: () => void;
}

const DeleteDialog = ({ open, onDelete }: IDialogProps) => {
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(setDeleteDialog({ show: false }));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Do you want to delete?</DialogTitle>
      <DialogContent>
        {/* <DialogContentText id="alert-dialog-description">
          Delete Description
        </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="small" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" size="small" onClick={onDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
