import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { useCallback } from "react";
import { db } from "../firebase";
import {
  Company,
  Profile,
  setCompany,
  setProfile,
  setUsers,
} from "../store/data.store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setShowBackdrop, setShowSnackbar } from "../store/ui.store";
import useError from "./useError";
import useFileUpload from "./useFileUpload";
import useSignup from "./useSignup";
import { useNavigate } from "react-router-dom";

const useProfile = () => {
  const dispatch = useAppDispatch();
  const handleError = useError();
  const { uploadFile } = useFileUpload();
  const { createTenant } = useSignup();
  const navigate = useNavigate();

  const { user, profile, company, users } = useAppSelector(
    (state) => state.data
  );

  const getCompanyInfo = useCallback(async () => {
    if (company) return;
    try {
      dispatch(setShowBackdrop(true));
      const path = `/tenants/${user?.tenantId}`;
      const snap = await getDoc(doc(db, path));
      const data = snap.data() as Company;
      dispatch(setCompany(data));
      if (data.planId === null) {
        navigate("/subscription");
      }
    } catch (error) {
      handleError(error, "getCompanyInfo");
    } finally {
      dispatch(setShowBackdrop(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, dispatch, user?.tenantId]);

  const getProfile = useCallback(async () => {
    if (profile) return;
    try {
      dispatch(setShowBackdrop(true));
      const path = `/tenants/${user?.tenantId}/users/${user?.uid}`;
      const snap = await getDoc(doc(db, path));
      const data = { ...snap.data(), uid: snap.id } as Profile;
      dispatch(setProfile(data));
    } catch (error) {
      handleError(error, "getProfile");
    } finally {
      dispatch(setShowBackdrop(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, profile, user?.tenantId, user?.uid]);

  const getUsers = useCallback(
    async (update?: boolean) => {
      if (!update && users.length !== 0) return;
      try {
        dispatch(setShowBackdrop(true));
        const path = `/tenants/${user?.tenantId}/users`;
        const snap = await getDocs(collection(db, path));
        const data = snap.docs.map(
          (e) => ({ ...e.data(), uid: e.id } as Profile)
        );
        dispatch(setUsers(data));
      } catch (error) {
        handleError(error, "getUsers");
      } finally {
        dispatch(setShowBackdrop(false));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, user?.tenantId, users.length]
  );

  const updateProfile = async (data: Partial<Profile>) => {
    try {
      dispatch(setShowBackdrop(true));
      const path = `/tenants/${user?.tenantId}/users/${user?.uid}`;
      await updateDoc(doc(db, path), {
        ...data,
        updatedAt: serverTimestamp(),
      });
      dispatch(
        setShowSnackbar({
          open: true,
          msg: "Profile updated successfully!",
          type: "success",
        })
      );
    } catch (error) {
      handleError(error, "updateProfile");
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const updateLogo = async (file: File) => {
    try {
      dispatch(setShowBackdrop(true));
      const path = `/tenants/${user?.tenantId}`;
      const fileData = await uploadFile(file, `${path}/logo`);
      await updateDoc(doc(db, path), {
        logo: fileData,
        updatedAt: serverTimestamp(),
      });
      dispatch(
        setShowSnackbar({
          open: true,
          msg: "Logo updated successfully!",
          type: "success",
        })
      );
    } catch (error) {
      handleError(error, "updateLogo");
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const checkUniqueFields = async (
    data: string,
    field: "email" | "phoneNumber"
  ) => {
    const q = query(collection(db, "users"), where(field, "==", data));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.length === 0;
  };

  const inviteTeamMember = async (
    email: string,
    role: string,
    password: string
  ) => {
    try {
      dispatch(setShowBackdrop(true));

      const isUnique = await checkUniqueFields(email, "email");
      if (!isUnique) {
        dispatch(
          setShowSnackbar({
            open: true,
            msg: "Email already exists!",
            type: "error",
          })
        );
        return false;
      }

      const res = await addDoc(collection(db, "users"), {
        email,
        role,
        registered: false,
        tenantId: user?.tenantId,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });

      const result = await createTenant(password, res.id);
      if (result) {
        dispatch(
          setShowSnackbar({
            open: true,
            msg: "You have invited the new member successfully!",
            type: "success",
          })
        );
        await getUsers(true);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      handleError(error, "inviteTeamMember");
      return false;
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const removeMember = async (uid: string, email: string) => {
    try {
      dispatch(setShowBackdrop(true));
      const url =
        process.env.NODE_ENV === "development"
          ? "http://127.0.0.1:5001/decorflow-40fd9/us-central1/removeUser"
          : "https://removeUser-qptp7l3cfq-uc.a.run.app";
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ uid, tenantId: user?.tenantId, email }),
      });
      const res = await response.json();
      dispatch(
        setShowSnackbar({
          open: true,
          msg: res.message,
          type: response.status === 200 ? "success" : "error",
        })
      );
      await getUsers(true);
    } catch (error) {
      handleError(error, "inviteTeamMember");
      return false;
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  return {
    getCompanyInfo,
    getProfile,
    updateProfile,
    updateLogo,
    getUsers,
    inviteTeamMember,
    removeMember,
  };
};
export default useProfile;
