import { Box, Tab, Tabs } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";
import useProfile from "../../hooks/useProfile";
import { useAppSelector } from "../../store/hooks";
import GeneralTab from "./_general-tab";
import TeamsTab from "./_teams-tab";
import Subscription from "../../components/subscription";

const TABS = [
  "General",
  "Teams",
  "Subscription",
  "Invoices",
  "Quotation Settings",
] as const;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const Profile = () => {
  const [value, setValue] = useState(0);
  const { getProfile } = useProfile();
  const profile = useAppSelector((state) => state.data.profile);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!profile) {
      getProfile();
    }
  }, [getProfile, profile]);

  return (
    <Box sx={{ bgcolor: "background.paper" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {TABS.map((tab) => (
          <Tab key={tab} label={tab} />
        ))}
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <GeneralTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <TeamsTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Subscription />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        Item Four
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        Item Five
      </CustomTabPanel>
    </Box>
  );
};

export default Profile;
