import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import useCatalogue from "../../hooks/useCatalogue";
import useQuotation from "../../hooks/useQuotation";
import { IQuotation, setQuotation } from "../../store/data.store";
import { useAppDispatch } from "../../store/hooks";
import QuotationDialog from "./quotation-dialog";
import SectionForm from "./section-form";
import { MOCK_QUOTATION_DATA } from "../../mockData";

const CreateQuotationPage = () => {
  const dispatch = useAppDispatch();
  const { saveAsDraft } = useQuotation();
  // const navigate = useNavigate();

  const methods = useForm<IQuotation>({
    defaultValues: MOCK_QUOTATION_DATA,
  });

  const { handleSubmit, getValues } = methods;

  const { getItems } = useCatalogue();

  const [openQuotation, setOpenQuotation] = useState(false);

  const onSubmit = (data: IQuotation) => {
    console.log(data);
    dispatch(setQuotation(data));
    setOpenQuotation(true);
    // navigate("generate");
  };

  useEffect(() => {
    getItems();
  }, [getItems]);

  return (
    <div>
      <Typography
        variant="h5"
        component="h1"
        sx={{ fontWeight: "bold", textAlign: "center" }}
      >
        Quotation
      </Typography>
      <QuotationDialog
        open={openQuotation}
        onClose={() => setOpenQuotation(false)}
      />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <SectionForm />
          <div className="my-5 flex gap-5 items-center justify-center">
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                saveAsDraft(getValues());
              }}
            >
              Save as Draft
            </Button>
            <Button variant="contained" type="submit" size="small">
              Generate Quotation
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default CreateQuotationPage;
