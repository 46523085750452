import { Route, Routes } from "react-router-dom";
import DeleteDialog from "./components/deletedialog";
import Loader from "./components/loader";
import Navbar from "./components/navbar";
import CataloguePage from "./pages/catalogue";
import ClientsPage from "./pages/clients";
import DashboardPage from "./pages/dashboard";
import LeadsPage from "./pages/leads";
import LoginPage from "./pages/login";
import Profile from "./pages/profile";
import ProjectsPage from "./pages/projects";
import Quotation from "./pages/quotation";
import CreateQuotationPage from "./pages/quotation/create-quotation-page";
import GenerateQuotation from "./pages/quotation/generate-quotation";
import Settings from "./pages/settings";
import { useAppSelector } from "./store/hooks";
import AuthProvider from "./utils/AuthContext";
import Signup from "./pages/signup";
import VerifyAccount from "./pages/verify-account";
import AlertMessage from "./components/AlertMessage/AlertMessage";
import Subscription from "./components/subscription";

function App() {
  const deleteDialog = useAppSelector((state) => state.ui.deleteDialog);

  return (
    <div className="font-primary">
      <Loader />
      <DeleteDialog open={deleteDialog.show} onDelete={deleteDialog.onDelete} />
      <AlertMessage />
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Navbar />}>
            <Route path="/" element={<DashboardPage />} />
            <Route path="leads" element={<LeadsPage />} />
            <Route path="clients" element={<ClientsPage />} />
            <Route path="projects" element={<ProjectsPage />} />
            <Route path="quotation" element={<Quotation />} />
            <Route path="quotation/create" element={<CreateQuotationPage />} />
            <Route path="quotation/generate" element={<GenerateQuotation />} />
            <Route path="catalogue" element={<CataloguePage />} />
            <Route path="settings" element={<Settings />} />
            <Route path="profile" element={<Profile />} />
            <Route path="verify-account" element={<VerifyAccount />} />
            <Route path="subscription" element={<Subscription />} />
          </Route>
          <Route path="login" element={<LoginPage />} />
          <Route path="signup" element={<Signup />} />
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
