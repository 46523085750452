import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useAppSelector } from "../../store/hooks";
import { currency, dateFormat } from "../../utils/constants";
import TermsAndConditions from "./terms-and-conditions";

interface QuotationDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function QuotationDialog({
  open,
  onClose,
}: QuotationDialogProps) {
  const quotation = useAppSelector((state) => state.data.quotation);
  const quotationFor = useAppSelector((state) => state.data.quotationFor);
  const lead = quotationFor?.lead;
  const project = quotationFor?.projectInfo?.find(
    (e) => e.docId === quotation?.project
  );

  const getAmountAsNumber = (amount: string): number => {
    return parseFloat(amount);
  };

  const calculateTotal = () => {
    return !quotation?.titles
      ? 0
      : quotation.titles.reduce((acc, title) => {
          return (
            acc +
            title.fields.reduce(
              (acc2, current) => acc2 + getAmountAsNumber(current.Amount),
              0
            )
          );
        }, 0);
  };

  return !open ? null : (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="quotation-dialog"
      aria-describedby="quotation-description"
      scroll="paper"
      maxWidth="lg"
    >
      {/* <DialogTitle id="dialog-title" className="text-center">
        Quotation
      </DialogTitle> */}
      <DialogContent id="downloadable-component" className="print-only">
        <Box className="print:block print-style">
          <div className="hidden print:block watermark">
            <img
              className="min-w-36 min-h-36"
              alt="logo"
              src="/logo_text.svg"
            />
          </div>
          <div className="flex justify-between my-5">
            <img className="h-11" alt="logo" src="/logo_text.svg" />
            <div>
              {/* <div className="flex gap-4">
                <Typography variant="body2" component="div">
                  Quotation#
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ fontWeight: "medium", textAlign: "center" }}
                >
                  001
                </Typography>
              </div> */}
              <div className="flex gap-4">
                {/* <Typography variant="body2" component="div">
                  Quotation Date
                </Typography> */}
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ fontWeight: "medium", textAlign: "center" }}
                >
                  {dateFormat.format(new Date())}
                </Typography>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 my-10 text-sm">
            <div>
              <div className="text-primaryDark font-semibold mb-3">
                Quotation to
              </div>
              <div className="font-semibold">{lead?.name}</div>
              <div>
                {lead?.address.line1}
                <br />
                {lead?.address.line2}
                <br />
                {lead?.address.city}, {lead?.address.state} -{" "}
                {lead?.address.pincode}
              </div>
              <div>+91 {lead?.mobile}</div>
            </div>
            <div>
              <div className="text-primaryDark font-semibold mb-3">
                Project Details
              </div>
              <div>
                <strong>Property Name:</strong> {project?.name}
              </div>
              <div>
                <strong>Property Type:</strong> {project?.bhk}
              </div>
              <div>
                <strong>Build-up Area:</strong> {project?.area?.value}{" "}
                {project?.area?.units}
              </div>
              <div>
                <strong>Address:</strong> {project?.address.line1},{" "}
                {project?.address.line2}, {project?.address.city}
                {project?.address.state} - {project?.address.pincode}
              </div>
            </div>
          </div>
          <div className="border">
            {quotation?.titles.map((title, index) => (
              <div
                key={index}
                className="mb-4 print-section break-inside-avoid"
              >
                <div className="w-full bg-primary text-white mb-2 text-sm font-semibold px-4 py-2">
                  <h2>{title.title}</h2>
                </div>
                <table className="w-full table-auto">
                  <thead className="border-b">
                    <tr>
                      {Object.keys(title.fields[0]).map((fieldName, i) => (
                        <th
                          className={`font-semibold text-sm px-4 py-2 ${
                            i === 0 ? "text-left" : "text-center"
                          }`}
                          key={i}
                        >
                          {fieldName}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {title.fields.map((field, i) => {
                      return (
                        <tr key={i} className="border-b">
                          {Object.values(field).map((value, j) => (
                            <td
                              className={`font-medium text-sm px-4 py-2 ${
                                j === 0 ? "text-left" : "text-center"
                              }`}
                              key={j}
                            >
                              {value}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                    <tr className="">
                      <td className="font-bold text-base px-4 py-2">Total</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td
                        colSpan={2}
                        className="font-bold text-base text-right px-4 py-2"
                      >
                        {currency.format(
                          title.fields.reduce((acc, current) => {
                            return acc + getAmountAsNumber(current.Amount);
                          }, 0)
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ))}
          </div>
          <div className="border mt-10 print-section">
            <div className="w-full bg-primary text-white mb-2 font-semibold text-xl px-4 py-2">
              Summary
            </div>
            {quotation?.titles.map((title, index) => (
              <div key={index} className="mb-4">
                <table className="w-full table-auto">
                  <tbody>
                    <td className="font-bold text-base px-4 py-2">
                      {title.title}
                    </td>
                    <td className="font-bold text-base text-right px-4 py-2">
                      {currency.format(
                        title.fields.reduce((acc, current) => {
                          return acc + getAmountAsNumber(current.Amount);
                        }, 0)
                      )}
                    </td>
                  </tbody>
                </table>
              </div>
            ))}
          </div>
          <div className="flex justify-between p-4 font-bold text-xl">
            <div>Total Quotation</div>
            <div>{currency.format(calculateTotal())}</div>
          </div>
          <TermsAndConditions />
        </Box>
      </DialogContent>
      <div className="print:hidden">
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          <Button
            onClick={() => {
              window.print();
            }}
            autoFocus
          >
            Download
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
