import { Box, Button, InputAdornment, Paper, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import usePlans, { Plan } from "../../hooks/usePlans";
import useSignup, { SignupForm } from "../../hooks/useSignup";
import { useAppSelector } from "../../store/hooks";
import { serializeObject } from "../../utils/constants";

const VALID_STEPS = ["1", "2", "3", "4", "5"];

const LEFT_CONTENT = {
  "1": {
    title: "Company Name",
    desc: "Your company name helps us identify your business and tailor our services to meet your specific needs. It will be displayed on your dashboard and used in communication.",
    image: "./images/company.svg",
  },
  "2": {
    title: "Email Address",
    desc: "Your email is used for logging into your account and for communication. We will send important updates and information to this email address.",
    image: "./images/email.svg",
  },
  "3": {
    title: "Phone Number",
    desc: "Your phone number is used for communication and adds an extra layer of security. We may use it for account recovery and important notifications.",
    image: "./images/phone.svg",
  },
  "4": {
    title: "Password",
    desc: "Your password ensures your account is secure. Please create a strong password to protect your personal and company information.",
    image: "./images/password.svg",
  },
  "5": {
    title: "Account Created",
    desc: "Congratulations! Your account has been set up. Click the 'Log In' button to access your dashboard and start managing your projects with DecorFlow.",
    image: "./images/verify-email.svg",
  },
} as const;

const Signup = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { getPlanByTypeAndCycle } = usePlans();
  const { updateUser, createTenant, error: signupError } = useSignup();
  const { control, handleSubmit, setError } = useForm<SignupForm>({
    defaultValues: {
      companyName: "",
      phoneNumber: "",
      email: "",
      password: "",
      cnfPassword: "",
    },
  });

  const [plan, setPlan] = useState<Plan | undefined>();

  const planType = searchParams.get("planType");
  const billingCycle = searchParams.get("billingCycle");

  const isLoading = useAppSelector((state) => state.ui.showBackdrop);
  const signupStep = searchParams.get("step");

  const onSubmit: SubmitHandler<SignupForm> = async (data) => {
    if (signupStep === "4") {
      if (data.password !== data.cnfPassword) {
        setError("password", {
          message: "Password & Confirm password does not match",
        });
        setError("cnfPassword", {
          message: "Password & Confirm password does not match",
        });
        return;
      }
      await createTenant(data.password);
    } else {
      const serializedData = serializeObject({
        ...data,
        phoneNumber: signupStep === "3" ? `+91${data.phoneNumber}` : "",
        planId: plan?.docId,
      });

      await updateUser(serializedData);
    }
  };

  useEffect(() => {
    if (signupError) {
      setError(signupError["phoneNumber"] ? "phoneNumber" : "email", {
        message: signupError["phoneNumber"] ?? signupError["email"],
      });
    }
  }, [signupError, setError]);

  useEffect(() => {
    if (planType && billingCycle && !plan) {
      getPlanByTypeAndCycle(planType, billingCycle).then((data) =>
        setPlan(data)
      );
    }
  }, [billingCycle, getPlanByTypeAndCycle, plan, planType]);

  useEffect(() => {
    if (!signupStep || !VALID_STEPS.includes(signupStep)) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("step", "1");
      setSearchParams(newSearchParams);
    }
  }, [searchParams, setSearchParams, signupStep]);

  return !signupStep || !VALID_STEPS.includes(signupStep) ? null : (
    <div className="h-screen grid grid-cols-1 md:grid-cols-2">
      <div className="hidden p-10 border-r md:block">
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <img
            className="h-96 w-auto"
            src={LEFT_CONTENT[signupStep as keyof typeof LEFT_CONTENT].image}
            alt={LEFT_CONTENT[signupStep as keyof typeof LEFT_CONTENT].title}
          />
          <h2 className="text-2xl font-semibold text-primary">
            {LEFT_CONTENT[signupStep as keyof typeof LEFT_CONTENT].title}
          </h2>
          <div className="text-lg">
            {LEFT_CONTENT[signupStep as keyof typeof LEFT_CONTENT].desc}
          </div>
        </Box>

        {/* <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <div className="text-2xl font-semibold text-center text-primary">
            Billing Summary
          </div>
          <div className="text-black font-semibold text-lg">
            {plan.type} Plan
          </div>
          <div className="text-primary font-semibold flex justify-between">
            <span>Billing Cycle</span>
            <span className="text-black">
              {plan.billingCycle === "monthly" ? "Monthly" : "Yearly"}
            </span>
          </div>
          <div className="text-primary font-semibold flex justify-between">
            <span>Subtotal</span>
            <span className="text-black">{currency.format(plan.price)}</span>
          </div>
          <div className="text-primary font-semibold flex justify-between border-t pt-3">
            <span>Discount</span>
            <span className="text-black">
              {currency.format(plan.discount || 0)}
            </span>
          </div>
          <div className="text-primary font-semibold flex justify-between border-b pb-3">
            <span>Promocode</span>
            <span className="text-black">
              {currency.format(plan.discount || 0)}
            </span>
          </div>
          <div className="text-primary font-semibold flex justify-between">
            <span>Total</span>
            <span className="text-black">
              {currency.format(plan.price || 0)}
            </span>
          </div>
        </Box> */}
      </div>
      <div className="bg-signup bg-no-repeat bg-contain h-full bg-bottom p-10 flex flex-col items-center">
        <div className="text-3xl font-semibold mb-10 sm:text-4xl">
          Let’s get started
        </div>
        <Paper
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            width: "100%",
            p: 3,
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          {signupStep === "1" && (
            <Controller
              name="companyName"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id="companyName"
                  margin="dense"
                  placeholder="Enter your company name"
                  fullWidth
                  disabled={isLoading}
                  required
                  {...field}
                  error={!!error}
                  helperText={!!error && "Please enter company name"}
                />
              )}
            />
          )}
          {signupStep === "2" && (
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id="email"
                  type="email"
                  margin="dense"
                  placeholder="Enter your email"
                  fullWidth
                  required
                  {...field}
                  error={!!error}
                  helperText={!!error && (error.message || "Invalid Email")}
                />
              )}
            />
          )}
          {signupStep === "3" && (
            <Controller
              name="phoneNumber"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  id="phoneNumber"
                  type="tel"
                  margin="dense"
                  placeholder="Enter your Phone Number"
                  fullWidth
                  required
                  {...field}
                  error={!!error}
                  helperText={
                    !!error && (error.message || "Invalid Phone Number")
                  }
                  inputProps={{ maxLength: 10 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          )}
          {signupStep === "4" && (
            <>
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="password"
                    label="Password"
                    type="password"
                    margin="dense"
                    fullWidth
                    required
                    {...field}
                    error={!!error}
                    helperText={
                      !!error && (error.message || "Invalid Password")
                    }
                  />
                )}
              />
              <Controller
                name="cnfPassword"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="cnfPassword"
                    label="Confirm Password"
                    type="password"
                    margin="dense"
                    fullWidth
                    required
                    {...field}
                    error={!!error}
                    helperText={
                      !!error && (error.message || "Invalid Password")
                    }
                  />
                )}
              />
            </>
          )}
          {signupStep === "5" && (
            <>
              <div>
                Your account has been successfully created! Please log in to
                your account to get started.
              </div>
              <Link to="/login">
                <Button variant="contained" size="large" disabled={isLoading}>
                  Log In
                </Button>
              </Link>
            </>
          )}
          {signupStep !== "5" && (
            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={isLoading}
            >
              Continue
            </Button>
          )}
        </Paper>
      </div>
    </div>
  );
};

export default Signup;
