import { Box } from "@mui/material";
import { useState } from "react";
import { PLANS } from "../../utils/constants";
import usePlans from "../../hooks/usePlans";

const Subscription = () => {
  const { updatePlan } = usePlans();
  const [selected, setSelected] = useState<keyof typeof PLANS>("monthly");

  const togglePlan = (plan: keyof typeof PLANS) => {
    setSelected(plan);
  };

  return (
    <Box>
      <div id="pricing" className="py-20" style={{ scrollMarginTop: "50px" }}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-14">
          <div className="">
            <h2 className="text-3xl font-semibold text-primary text-center mb-5">
              Our Pricing Plan
            </h2>
            <p className="text-center max-w-xl mx-auto">
              {`Choose the Plan That's Right for You`}
            </p>
          </div>
          <div className="max-w-min flex border rounded-md mx-auto my-5">
            <button
              onClick={() => togglePlan("monthly")}
              className={`text-white font-medium px-10 py-2.5 text-center bg-primary rounded-l-md ${
                selected !== "monthly" && "bg-slate-400"
              }`}
            >
              Monthly
            </button>
            <div className="flex flex-col relative text-center">
              <button
                onClick={() => togglePlan("yearly")}
                className={`text-white font-medium px-10 py-2.5 text-center bg-primary rounded-r-md ${
                  selected !== "yearly" && "bg-slate-400"
                }`}
              >
                Yearly
              </button>
              <span className="bg-green-600 w-full rounded-md text-white text-xs py-0.5 absolute -bottom-2.5 text-center font-semibold">
                SAVE 30% OFF
              </span>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
            {PLANS[selected].map((plan) => (
              <div
                key={plan.type}
                className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow bg-gradient-to-b from-secondary to-secondary2"
              >
                <h3 className="mb-4 text-2xl font-semibold text-primaryDark">
                  {plan.type}
                </h3>
                <p className="text-gray-500">{plan.desc}</p>
                <div className="flex justify-center items-baseline my-8">
                  <span
                    className={`mr-2 text-4xl font-semibold ${
                      plan.discount && "line-through"
                    }`}
                  >
                    ₹{plan?.price}
                  </span>
                  {plan.discount && (
                    <span className="mr-2 text-4xl font-semibold text-green-700">
                      ₹{plan.discount}
                    </span>
                  )}

                  <span className="text-gray-500">
                    /{selected === "monthly" ? "month" : "year"}
                  </span>
                </div>
                <ul className="mb-8 space-y-4 text-left">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex items-center space-x-3">
                      <img
                        className="flex-shrink-0 w-5 h-5"
                        src="./images/tick.svg"
                        alt={feature}
                      />
                      <span className="text-sm">{feature}</span>
                    </li>
                  ))}
                </ul>
                <button
                  className="text-white font-medium rounded-lg px-5 py-2.5 text-center bg-gradient-to-r from-primary to-primaryDark hover:from-primaryDark hover:to-primary"
                  onClick={() => {
                    updatePlan(plan);
                  }}
                >
                  Get started
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Subscription;
