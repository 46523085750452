import { DeleteForever, QueueOutlined } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, MenuItem } from "@mui/material";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import InputField from "../../components/inputField";
import useLeads from "../../hooks/useLeads";
import useProjects from "../../hooks/useProjects";
import { IQuotation, setQuotationFor } from "../../store/data.store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setShowBackdrop } from "../../store/ui.store";
import FieldsForm from "./fields-form";

const SectionForm = () => {
  const dispatch = useAppDispatch();
  const { getLeadByMobile } = useLeads("Lead");
  const { getLeadProjects } = useProjects();
  const methods = useFormContext<IQuotation>();
  const [leadMobile, setLeadMobile] = useState<string | undefined>();
  const quotationFor = useAppSelector((state) => state.data.quotationFor);

  const {
    register,
    control,
    formState: { errors },
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "titles",
  });

  return (
    <div className="flex flex-col gap-10 text-sm mt-10">
      <div className="max-w-screen-md grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
        <InputField
          id="lead"
          label="Lead Mobile"
          {...register("lead", { required: true })}
          required
          hiddenLabel
          error={!!errors.lead}
          variant="outlined"
          fullWidth
          inputProps={{ maxLength: 10 }}
          onChange={(e) => setLeadMobile(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">+91</InputAdornment>
            ),
          }}
        />
        <Button
          size="small"
          variant="contained"
          onClick={async () => {
            if (leadMobile) {
              dispatch(setShowBackdrop(true));
              const lead = await getLeadByMobile(leadMobile);
              const project = await getLeadProjects(lead?.docId!);
              dispatch(setQuotationFor({ lead, projectInfo: project }));
              dispatch(setShowBackdrop(false));
            }
          }}
        >
          Search
        </Button>
        {quotationFor?.projectInfo && (
          <InputField
            id="project"
            {...register("project", { required: true })}
            required
            label="Project"
            error={!!errors.project}
            variant="outlined"
            disabled={!quotationFor?.projectInfo}
            fullWidth
            select
          >
            {quotationFor?.projectInfo?.map((option) => (
              <MenuItem key={option.docId} value={option.docId}>
                {option.name}
              </MenuItem>
            ))}
          </InputField>
        )}
      </div>

      {fields.map((title, titleIndex) => (
        <div key={titleIndex} className="border-b-2">
          <div className="max-w-sm flex gap-5 mb-2">
            <InputField
              id={title.id}
              label="Section"
              {...register(`titles.${titleIndex}.title`, {
                required: true,
              })}
              fullWidth
              required
              error={!!errors.titles?.[titleIndex]?.title}
              variant="outlined"
            />
            {fields.length !== 1 && (
              <IconButton onClick={() => remove(titleIndex)}>
                <DeleteForever className="text-red" />
              </IconButton>
            )}
          </div>
          <div className="border">
            <div className="bg-highlight2 grid grid-cols-8 font-semibold h-14 items-center px-5 gap-3">
              <div className="text-left col-span-2">Product</div>
              <div className="text-left">Dimensions</div>
              <div className="text-left">Unit</div>
              <div className="text-left">Area</div>
              <div className="text-left">Cost/Unit</div>
              <div className="text-left">Amount</div>
              <div className="text-left">Action</div>
            </div>
            <FieldsForm titleIndex={titleIndex} />
          </div>
        </div>
      ))}
      <div>
        <Button
          size="small"
          variant="outlined"
          startIcon={<QueueOutlined />}
          onClick={() =>
            append({
              title: "",
              fields: [
                {
                  Product: "",
                  Dimensions: "",
                  Unit: "",
                  Area: "",
                  Rate: "",
                  Amount: "",
                },
              ],
            })
          }
        >
          Add Another Section
        </Button>
      </div>
    </div>
  );
};

export default SectionForm;
