import { DeleteForever, EditOutlined } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import AddProjectDialog from "../../components/AddProjectDialog";
import useQuotation from "../../hooks/useQuotation";
import { Project } from "../../store/data.store";
import { useAppSelector } from "../../store/hooks";
import { Link } from "react-router-dom";

const columns = [
  { id: "name", label: "Name" },
  { id: "mobile", label: "Mobile Number" },
  { id: "projectName", label: "Project Name" },
  { id: "action", label: "Action" },
];

export default function QuotationsPage() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { getQuotations } = useQuotation();

  const [open, setOpen] = useState<{ project?: Project; open: boolean }>({
    open: false,
    project: undefined,
  });

  const { quotations } = useAppSelector((state) => state.data);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getQuotations();
  }, [getQuotations]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Toolbar
        className="flex justify-between"
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography variant="h6" id="tableTitle" component="div">
          Projects
        </Typography>

        <Link to="create">
          <Button className="min-w-min" variant="contained" size="small">
            Create Quotation
          </Button>
        </Link>
      </Toolbar>
      <AddProjectDialog
        open={open.open}
        project={open.project}
        onClose={() => setOpen({ open: false, project: undefined })}
      />
      <TableContainer className="h-[calc(100vh-250px)]">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {quotations
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.docId}>
                    <TableCell>{"lead?.name"}</TableCell>
                    <TableCell>{row?.lead}</TableCell>
                    <TableCell>{row.project}</TableCell>
                    <TableCell>
                      <div className="flex gap-2 items-center">
                        <Tooltip title="Update Project">
                          <IconButton
                            onClick={() => {
                              // setOpen({ open: true, project: row });
                            }}
                          >
                            <EditOutlined className="text-primary" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Project">
                          <IconButton onClick={() => {}}>
                            <DeleteForever className="text-red" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={quotations.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
