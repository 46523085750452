import { TextField, TextFieldProps } from "@mui/material";
import { ReactNode, forwardRef } from "react";

interface InputFieldProps {
  children?: ReactNode;
}

const InputField = forwardRef<
  HTMLInputElement,
  InputFieldProps & TextFieldProps
>(({ label, children, ...others }, ref) => (
  <TextField
    ref={ref}
    helperText={others.error ? `${label} is invalid / required` : null}
    error={!!others.error}
    size="small"
    label={label}
    variant="standard"
    {...others}
    inputProps={{ style: { fontSize: 14 } }}
    InputLabelProps={{ style: { fontSize: 14 } }}
  >
    {children}
  </TextField>
));

InputField.displayName = "InputField";

export default InputField;
