import {
  DeleteForever,
  EditOutlined,
  TransferWithinAStationOutlined,
} from "@mui/icons-material";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import useLeads from "../../hooks/useLeads";
import { Lead } from "../../store/data.store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setDeleteDialog } from "../../store/ui.store";
import { LEAD_STATUS } from "../../utils/constants";
import AddClientDialog from "./_AddClientDialog";

const columns = [
  { id: "name", label: "Name" },
  { id: "mobile", label: "Mobile" },
  { id: "location", label: "Location" },
  { id: "projectType", label: "Project Type" },
  { id: "budget", label: "Budget Range" },
  { id: "status", label: "Status" },
  { id: "actions", label: "Actions" },
];

// const PROJECT_TYPE_COLOR = {
//   Residential: "success",
//   Commercial: "info",
//   Renovation: "warning",
// } as const;

export default function ClientsPage() {
  const dispatch = useAppDispatch();
  const { getLeads, updateLead, deleteLead } = useLeads("Client");
  const clients = useAppSelector((state) => state.data.clients);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState<{ client?: Lead; open: boolean }>({
    open: false,
    client: undefined,
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleMenuItemClick = async (docId: string, status: string) => {
    await updateLead(docId, { status: status });
    setAnchorEl(null);
  };

  useEffect(() => {
    getLeads();
  }, [getLeads]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Toolbar
        className="flex justify-between"
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography variant="h6" id="tableTitle" component="div">
          Clients
        </Typography>

        <Button
          className="min-w-min"
          variant="contained"
          size="small"
          onClick={() => setOpen({ open: true })}
        >
          Add Client
        </Button>
      </Toolbar>
      <AddClientDialog
        open={open.open}
        client={open.client}
        onClose={() => setOpen({ open: false, client: undefined })}
      />
      <TableContainer className="h-[calc(100vh-250px)]">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {clients
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.mobile}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.mobile}</TableCell>
                    <TableCell>{row.address.city}</TableCell>
                    <TableCell>
                      {/* <Chip
                        color={PROJECT_TYPE_COLOR[row.projectType]}
                        size="small"
                        variant="filled"
                        label={row.projectType}
                      /> */}
                    </TableCell>
                    {/* <TableCell>{row.budget ?? "-"}</TableCell> */}
                    <TableCell>
                      <div>
                        <Button
                          className="text-sm"
                          id={`basic-button-${row.docId}`}
                          size="small"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                        >
                          {row.status}
                        </Button>
                        <Menu
                          id={row.docId}
                          anchorEl={anchorEl}
                          open={openMenu}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          {LEAD_STATUS.map((status) => (
                            <MenuItem
                              dense
                              key={`${row.docId}-${status}`}
                              onClick={(_) =>
                                handleMenuItemClick(row.docId!, status)
                              }
                            >
                              {status}
                            </MenuItem>
                          ))}
                        </Menu>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex gap-2">
                        <Tooltip title="Update Lead">
                          <IconButton
                            onClick={() => {
                              setOpen({ open: true, client: row });
                            }}
                          >
                            <EditOutlined className="text-primary" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Lead">
                          <IconButton
                            onClick={() => {
                              dispatch(
                                setDeleteDialog({
                                  show: true,
                                  onDelete: () => {
                                    deleteLead(row.docId!);
                                  },
                                })
                              );
                            }}
                          >
                            <DeleteForever className="text-red" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Convert To Client">
                          <IconButton
                            onClick={() => {
                              //
                            }}
                          >
                            <TransferWithinAStationOutlined className="text-primaryDark" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={clients.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
