import { DeleteForever, EditOutlined } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import useCatalogue from "../../hooks/useCatalogue";
import { ICatalogue } from "../../store/catalogue.store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setDeleteDialog } from "../../store/ui.store";
import AddCatalogueDialog from "./_AddCatalogueDialog";

const columns = [
  { id: "name", label: "Name" },
  { id: "description", label: "Description" },
  { id: "status", label: "Status" },
  { id: "actions", label: "Actions" },
];

export default function CataloguePage() {
  const dispatch = useAppDispatch();
  const { getItems, deleteItem, updateItem } = useCatalogue();
  const catalogue = useAppSelector((state) => state.catalogue.catalogue);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState<{ catalogue?: ICatalogue; open: boolean }>({
    open: false,
    catalogue: undefined,
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getItems();
  }, [getItems]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Toolbar
        className="flex justify-between"
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography variant="h6" id="tableTitle" component="div">
          Catalogue
        </Typography>

        <Button
          className="min-w-min"
          variant="contained"
          size="small"
          onClick={() => setOpen({ open: true })}
        >
          Add Catalogue
        </Button>
      </Toolbar>
      <AddCatalogueDialog
        open={open.open}
        catalogue={open.catalogue}
        onClose={() => setOpen({ open: false, catalogue: undefined })}
      />
      <TableContainer className="h-[calc(100vh-250px)]">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {catalogue
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.docId}>
                    <TableCell>{row.itemName}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>
                      <Switch
                        checked={row.active}
                        onChange={(e) => {
                          updateItem({ ...row, active: e.target.checked });
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <div className="flex gap-2">
                        <Tooltip title="Update Item">
                          <IconButton>
                            <EditOutlined className="text-primary" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Item">
                          <IconButton
                            onClick={() => {
                              dispatch(
                                setDeleteDialog({
                                  show: true,
                                  onDelete: () => {
                                    deleteItem(`${row.docId}`, row.image?.path);
                                  },
                                })
                              );
                            }}
                          >
                            <DeleteForever className="text-red" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={catalogue.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
