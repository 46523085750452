import { AddCircleOutline, DeleteForever } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import InputField from "../../components/inputField";
import { IQuotation } from "../../store/data.store";

interface FieldsFormProps {
  titleIndex: number;
}

const FieldsForm = ({ titleIndex }: FieldsFormProps) => {
  const methods = useFormContext<IQuotation>();

  // const catalogue = useAppSelector((state) => state.catalogue.catalogue);

  const {
    register,
    control,
    formState: { errors },
  } = methods;

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: `titles.${titleIndex}.fields`,
  });

  const [manualProducts, setManualProducts] = useState<string[]>([
    "manual_entry",
  ]);

  return (
    <>
      {fields.map(({ id, ...field }, fieldIndex) => (
        <div
          key={id}
          className="grid grid-cols-8 items-start px-5 pt-2 pb-4 gap-3"
        >
          {Object.keys(field).map((key, index) => {
            const isNumber = !["Product", "Dimensions", "Unit"].includes(key);
            return key !== "Product" ? (
              <InputField
                key={`${key}-${index}`}
                label={key}
                {...register(
                  `titles.${titleIndex}.fields.${fieldIndex}.${key}`,
                  { required: key === "Amount" }
                )}
                defaultValue=""
                required={key === "Amount"}
                type={isNumber ? "number" : "text"}
                error={
                  !!errors.titles?.[titleIndex]?.fields?.[fieldIndex]?.[key]
                }
                // onChange={(e) => {
                //   const val = e.target.value;
                //   if (["Area", "Rate"].includes(key)) {
                //     update(fieldIndex, { ...field, [key]: val });
                //     console.log("AAAA", field, val);
                //     // update(fieldIndex, { ...field, 'Amount': val });
                //   }
                //   //
                // }}
              />
            ) : (
              <div
                key={`${key}-${index}`}
                className="flex flex-col gap-2 col-start-1 col-end-3"
              >
                {/* <InputField
                  key={`${key}-${index}`}
                  label={key}
                  {...register(
                    `titles.${titleIndex}.fields.${fieldIndex}.${key}`,
                    { required: true }
                  )}
                  select
                  required
                  defaultValue="manual_entry"
                  error={
                    !!errors.titles?.[titleIndex]?.fields?.[fieldIndex]?.[key]
                  }
                  onChange={(e) => {
                    if (e.target.value === "manual_entry") {
                      setManualProducts((prevProducts) => [
                        ...prevProducts,
                        `titles.${titleIndex}.fields.${fieldIndex}.${key}`,
                      ]);
                    } else {
                      setManualProducts((prevProducts) =>
                        prevProducts.filter(
                          (product) =>
                            product !==
                            `titles.${titleIndex}.fields.${fieldIndex}.${key}`
                        )
                      );
                    }
                  }}
                >
                  <MenuItem key="manual_entry" value="manual_entry">
                    <div className="flex items-center gap-2 text-primary font-semibold text-xs">
                      <AddCircleOutlineOutlined sx={{ width: 18 }} /> Enter
                      Product Name
                    </div>
                  </MenuItem>
                  {catalogue.map((option) => (
                    <MenuItem key={option.docId} value={option.itemName}>
                      {option.itemName}
                    </MenuItem>
                  ))}
                </InputField> */}
                {/* {manualProducts.includes("manualProducts") && ( */}
                <InputField
                  key={`${key}-${index}`}
                  {...register(
                    `titles.${titleIndex}.fields.${fieldIndex}.${key}`,
                    { required: true }
                  )}
                  label="Product Name"
                  defaultValue=""
                  required
                  fullWidth
                  placeholder="Enter the Product Name"
                  // multiline
                  error={
                    !!errors.titles?.[titleIndex]?.fields?.[fieldIndex]?.[key]
                  }
                />
                {/* // )} */}
              </div>
            );
          })}
          <div>
            {fields.length !== 1 && (
              <IconButton onClick={() => remove(fieldIndex)}>
                <DeleteForever className="text-red" />
              </IconButton>
            )}

            {fields.length === fieldIndex + 1 && (
              <IconButton
                onClick={() =>
                  append({
                    Product: "",
                    Dimensions: "",
                    Unit: "",
                    Area: "",
                    Rate: "",
                    Amount: "",
                  })
                }
              >
                <AddCircleOutline className="text-primary" />
              </IconButton>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default FieldsForm;
