import { FirebaseError } from "firebase/app";
import { useAppDispatch } from "../store/hooks";
import { setShowSnackbar } from "../store/ui.store";

interface ErrorCode {
  [errorCode: string]: string;
}

const ERROR_MESSAGE: ErrorCode = {
  // Firebase Authentication Errors
  "auth/argument-error":
    "Invalid arguments provided to Firebase Authentication.",
  "auth/cancelled-popup-request": "Sign-in cancelled by user.",
  "auth/credential-already-in-use": "Email already in use by another account.",
  "auth/email-already-in-use": "Email already in use by another account.",
  "auth/expired-action-code":
    "Action code (e.g., link verification) has expired.",
  "auth/invalid-action-code": "Invalid or used action code.",
  "auth/invalid-configuration": "Invalid Firebase project configuration.",
  "auth/invalid-credential": "Invalid credentials for sign-in.",
  "auth/invalid-email": "Invalid or poorly formatted email address.",
  "auth/invalid-password": "Invalid or weak password.",
  "auth/invalid-sender": "Invalid sender email address.",
  "auth/invalid-verification-id":
    "Invalid verification ID for phone verification.",
  "auth/invalid-verification-code":
    "Invalid verification code for phone verification.",
  "auth/internal-error":
    "Unexpected error on the Firebase Authentication server.",
  "auth/invalid-api-key": "Invalid API key used for authentication.",
  "auth/invalid-user-token": "Invalid or expired user token.",
  "auth/maximum-user-count-exceeded":
    "Maximum allowed users exceeded (custom user management).",
  "auth/missing-android-pkg-name":
    "Android package name missing in Firebase project configuration.",
  "auth/missing-continue-uri":
    "iOS/iPadOS continue URI missing in Firebase project configuration.",
  "auth/missing-emulator-config":
    "Emulator configuration missing in Firebase project configuration.",
  "auth/missing-ios-bundle-id":
    "iOS/iPadOS bundle ID missing in Firebase project configuration.",
  "auth/missing-verification-code": "Missing phone verification code.",
  "auth/multi-factor-auth-required":
    "Multi-factor authentication required for the user.",
  "auth/network-request-failed":
    "Network request failed during authentication.",
  "auth/operation-not-allowed":
    "The requested authentication operation is not allowed.",
  "auth/popup-blocked":
    "Popup window blocked by the browser during authentication.",
  "auth/popup-closed-by-user":
    "User closed the popup window before completing authentication.",
  "auth/recaptcha-invalid-parameter": "Invalid reCAPTCHA response parameter.",
  "auth/recaptcha-invalid-token": "Invalid or expired reCAPTCHA token.",
  "auth/redirect-cancelled-by-user":
    "User cancelled sign-in redirect flow with a federated provider.",
  "auth/rejected-credential":
    "Provider's credential was rejected (e.g., user rejected the sign-in request).",
  "auth/requires-recent-login":
    "User must re-authenticate due to security reasons.",
  "auth/session-expired":
    "User's session has expired and requires re-authentication.",
  "auth/sign-in-failed": "Sign-in process failed due to an unspecified error.",
  "auth/tenant-id-mismatch":
    "Tenant ID mismatch between request and Firebase project.",
  "auth/too-many-requests":
    "Too many requests made to Firebase Authentication server. Please try again later.",
  "auth/unauthorized-domain":
    "Domain not authorized for sign-in with a federated provider.",

  // Common Firestore Errors (add more as needed)
  "firestore/cancelled": "Firestore operation cancelled by the user.",
  "firestore/invalid-argument":
    "Invalid argument provided to a Firestore method.",
  "firestore/internal": "Unexpected error on the Firestore server.",
  "firestore/not-found": "Document or collection not found.",
  "firestore/permission-denied":
    "Insufficient permissions to access the requested data.",

  // Common Cloud Storage Errors (add more as needed)
  "storage/unauthorized":
    "Unauthorized access to Cloud Storage bucket or file.",
  "storage/quota-exceeded": "Storage quota exceeded for the project.",
  "storage/object-not-found": "Cloud Storage object not found.",
};

const useError = () => {
  const dispatch = useAppDispatch();

  const showError = (error: unknown, from?: string) => {
    console.error("ERROR", from, error);
    if (error instanceof FirebaseError) {
      const message = ERROR_MESSAGE[error.code] || error.message;
      dispatch(setShowSnackbar({ open: true, msg: message, type: "error" }));
    } else if (error instanceof Error) {
      dispatch(setShowSnackbar({ open: true, msg: `${error}`, type: "error" }));
    } else {
      dispatch(setShowSnackbar({ open: true, msg: `${error}`, type: "error" }));
    }
  };

  return showError;
};

export default useError;
