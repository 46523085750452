import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import useProjects from "../../hooks/useProjects";
import { useAppSelector } from "../../store/hooks";
import { DeleteForever, EditOutlined } from "@mui/icons-material";
import AddProjectDialog from "../../components/AddProjectDialog";
import { Project } from "../../store/data.store";
import useLeads from "../../hooks/useLeads";

const columns = [
  { id: "name", label: "Name" },
  { id: "mobile", label: "Mobile Number" },
  { id: "projectName", label: "Project Name" },
  { id: "projectType", label: "Project Type" },
  { id: "area", label: "Property Type" },
  { id: "address", label: "Project Address" },
  { id: "action", label: "Action" },
];

export default function ProjectsPage() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { getProjects } = useProjects();
  const { getLeads } = useLeads("Lead");

  const [open, setOpen] = useState<{ project?: Project; open: boolean }>({
    open: false,
    project: undefined,
  });

  const { projects, leads } = useAppSelector((state) => state.data);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    // FIXME: This hook will trigger continously, if the leads length is 0.
    if (open && leads.length === 0) {
      getLeads();
    }
  }, [getLeads, leads.length, open]);

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Toolbar
        className="flex justify-between"
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography variant="h6" id="tableTitle" component="div">
          Projects
        </Typography>

        <Button
          className="min-w-min"
          variant="contained"
          size="small"
          onClick={() => setOpen({ open: true })}
        >
          Add Project
        </Button>
      </Toolbar>
      <AddProjectDialog
        open={open.open}
        project={open.project}
        onClose={() => setOpen({ open: false, project: undefined })}
      />
      <TableContainer className="h-[calc(100vh-250px)]">
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {projects
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                const lead = leads.find((e) => e.docId === row.leadId);
                return (
                  <TableRow hover tabIndex={-1} key={row.docId}>
                    <TableCell>{lead?.name}</TableCell>
                    <TableCell>{lead?.mobile}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.type}</TableCell>
                    <TableCell>
                      {row.bhk} - {row.area?.value} {row.area?.units}
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={`${row.address.line1}, ${
                          row.address.line2 ?? ""
                        }, ${row.address.city}, ${row.address.state}, ${
                          row.address.pincode
                        }`}
                      >
                        <Button size="small">{row.address.city}</Button>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <div className="flex gap-2 items-center">
                        <Tooltip title="Update Project">
                          <IconButton
                            onClick={() => {
                              setOpen({ open: true, project: row });
                            }}
                          >
                            <EditOutlined className="text-primary" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Project">
                          <IconButton onClick={() => {}}>
                            <DeleteForever className="text-red" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={projects.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
