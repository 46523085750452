import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setShowBackdrop, setShowSnackbar } from "../store/ui.store";
import { PlanDetails } from "../utils/constants";
import useError from "./useError";

export interface Plan {
  docId: string;
  type: string;
  desc: string;
  price: number;
  discount: number | null;
  features: string[];
  billingCycle: string;
}

const usePlans = () => {
  const handleError = useError();
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.data.user);

  const getPlans = async () => {
    try {
      const q = query(collection(db, "plans"));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(
        (e) => ({ ...e.data(), docId: e.id } as Plan)
      );
    } catch (error) {
      handleError(error);
    } finally {
    }
  };

  const getPlanByTypeAndCycle = async (
    planType: string,
    billingCycle: string
  ) => {
    try {
      const q = query(
        collection(db, "plans"),
        where("type", "==", planType),
        where("billingCycle", "==", billingCycle),
        limit(1)
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.length === 0
        ? undefined
        : (querySnapshot.docs[0].data() as Plan);
    } catch (error) {
      handleError(error);
    } finally {
    }
  };

  const getPlanById = async (planId: string) => {
    try {
      const snap = await getDoc(doc(db, `/plans/${planId}`));
      return snap.exists() ? (snap.data() as Plan) : undefined;
    } catch (error) {
      handleError(error);
    } finally {
    }
  };

  const updatePlan = async (plan: PlanDetails) => {
    try {
      dispatch(setShowBackdrop(true));
      if (plan.type === "Free") {
        await updateDoc(doc(db, `/tenants/${user?.tenantId}`), {
          planId: plan.id,
          updatedAt: serverTimestamp(),
        });
        dispatch(
          setShowSnackbar({
            open: true,
            msg: "You have successfully upgraded your plan!",
          })
        );
      } else {
        //
      }
    } catch (error) {
      handleError(error);
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  return { getPlans, getPlanByTypeAndCycle, getPlanById, updatePlan };
};

export default usePlans;
