import {
  User,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import useError from "../hooks/useError";
import { setUser } from "../store/data.store";
import { useAppDispatch } from "../store/hooks";
import { setShowBackdrop } from "../store/ui.store";

interface AuthContextProps {
  currentUser: User | null;
  signIn: (email: string, password: string) => void;
  signOut: () => void;
  sendVerificationEmail: () => void;
  isLoading: boolean;
}

const AuthContext = createContext<AuthContextProps>({
  currentUser: null,
  signIn: () => {},
  signOut: () => {},
  sendVerificationEmail: () => {},
  isLoading: false,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

interface AuthProviderProps {
  children: ReactNode;
}
const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleError = useError();

  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
      if (user) dispatch(setUser(user));
      dispatch(setShowBackdrop(false));
    });

    return unsubscribe;
  }, [dispatch]);

  const getTenantId = async (email: string) => {
    const q = query(
      collection(db, "users"),
      where("email", "==", email),
      limit(1)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.length === 0
      ? undefined
      : querySnapshot.docs[0].data()["tenantId"];
  };

  const signIn = async (email: string, password: string) => {
    dispatch(setShowBackdrop(true));

    try {
      const tenantId = await getTenantId(email);
      if (!tenantId) {
        return;
      }
      auth.tenantId = tenantId;
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      if (!user.emailVerified) {
        sendVerificationEmail();
      } else {
        navigate("/");
      }
    } catch (err) {
      handleError(err);
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const sendVerificationEmail = () => {
    if (currentUser) {
      try {
        sendEmailVerification(currentUser).then(() => {
          navigate("/verify-account");
        });
      } catch (err) {
        handleError(err);
      }
    } else {
      // console.log("AAAA", "Current User Not Set!");
    }
  };

  const signOut = async () => {
    dispatch(setShowBackdrop(true));

    try {
      await auth.signOut();
      navigate("/login");
    } catch (err) {
      handleError(err);
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        signIn,
        signOut,
        isLoading,
        sendVerificationEmail,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
