import { Grid, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import useProjects from "../../hooks/useProjects";
import { Project } from "../../store/data.store";
import {
  AREA_UNITS,
  BHK_LIST,
  PROJECT_TYPE,
  serializeObject,
} from "../../utils/constants";
import { useAppSelector } from "../../store/hooks";
import useLeads from "../../hooks/useLeads";

interface AddProjectDialogProps {
  open?: boolean;
  leadId?: string;
  onClose: () => void;
  project?: Project;
}

export default function AddProjectDialog({
  leadId,
  onClose,
  project,
  open,
}: AddProjectDialogProps) {
  const openDialog = open || !!leadId;
  const { addProject, updateProject } = useProjects();
  const { getLeads } = useLeads("Lead");
  const { control, handleSubmit, setValue, reset, getValues } =
    useForm<Project>();

  const leads = useAppSelector((state) => state.data.leads);

  const onSubmit: SubmitHandler<Project> = async (data) => {
    const serializedData = serializeObject(data);
    const result = project
      ? await updateProject(project.docId, serializedData)
      : await addProject({
          ...serializedData,
        });
    if (result) {
      dialogClose();
    }
  };

  const dialogClose = () => {
    reset();
    onClose();
  };

  useEffect(() => {
    // FIXME: This hook will trigger continously, if the leads length is 0.
    if (open && leads.length === 0) {
      getLeads();
    }
  }, [getLeads, leads.length, open]);

  useEffect(() => {
    if (project) {
      setValue("leadId", project.leadId);
      setValue("name", project.name);
      setValue("type", project.type);
      setValue("area", project.area);
      setValue("bhk", project.bhk);
      setValue("status", project.status);
      setValue("clientBudget", project.clientBudget);
      setValue("address", project.address);
      setValue("startDate", project.startDate);
      setValue("endDate", project.endDate);
      setValue("notes", project.notes);
    } else if (leadId) {
      setValue("leadId", leadId);
    }
  }, [leadId, project, setValue]);

  return !openDialog ? null : (
    <Dialog open={openDialog} maxWidth="md" fullWidth>
      <DialogTitle>Add Project</DialogTitle>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Grid container columnSpacing={4}>
            <Grid item xs={12}>
              <Controller
                name="leadId"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="leadId"
                    label="Lead"
                    required
                    margin="dense"
                    select
                    fullWidth
                    variant="standard"
                    {...field}
                    defaultValue={leadId || ""}
                    disabled={!!leadId}
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                  >
                    {leads.map((option) => (
                      <MenuItem key={option.docId} value={option.docId}>
                        {`${option.name} - ${option.mobile} - ${option.address.city}`}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    label="Project Name"
                    fullWidth
                    variant="standard"
                    {...field}
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="type"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="type"
                    label="Project Type"
                    margin="dense"
                    select
                    required
                    fullWidth
                    variant="standard"
                    defaultValue=""
                    {...field}
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                  >
                    {PROJECT_TYPE.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            {getValues("type") === "Others" && (
              <Grid item xs={12}>
                <Controller
                  name="additionalType"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      id="additionalType"
                      label="Other Project Type"
                      placeholder="Enter the Project Type"
                      required
                      margin="dense"
                      fullWidth
                      variant="standard"
                      {...field}
                      error={!!error}
                      helperText={!!error && "This field is required!"}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={3}>
              <Controller
                name="area.units"
                control={control}
                // rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="area.units"
                    label="Units"
                    margin="dense"
                    select
                    // required
                    fullWidth
                    variant="standard"
                    defaultValue=""
                    {...field}
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                  >
                    {AREA_UNITS.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="area.value"
                control={control}
                // rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="area.value"
                    label="Built-Up Area"
                    margin="dense"
                    // required
                    fullWidth
                    variant="standard"
                    defaultValue=""
                    {...field}
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="bhk"
                control={control}
                // rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="bhk"
                    label="BHK"
                    margin="dense"
                    select
                    // required
                    fullWidth
                    variant="standard"
                    defaultValue=""
                    {...field}
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                  >
                    {BHK_LIST.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="address.line1"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="address.line1"
                    label="Address Line 1"
                    required
                    margin="dense"
                    fullWidth
                    variant="standard"
                    {...field}
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="address.line2"
                control={control}
                rules={{ required: false }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="address.line2"
                    label="Address Line 2"
                    margin="dense"
                    fullWidth
                    variant="standard"
                    {...field}
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="address.city"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="address.city"
                    label="City"
                    required
                    margin="dense"
                    fullWidth
                    variant="standard"
                    {...field}
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="address.state"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="address.state"
                    label="State"
                    required
                    margin="dense"
                    fullWidth
                    variant="standard"
                    {...field}
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="address.pincode"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="address.pincode"
                    label="Pincode"
                    required
                    margin="dense"
                    fullWidth
                    variant="standard"
                    {...field}
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                    inputProps={{
                      maxLength: 6,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="clientBudget"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    margin="dense"
                    id="budget"
                    label="Client Budget"
                    fullWidth
                    variant="standard"
                    {...field}
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="startDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    margin="dense"
                    id="startDate"
                    label="Start Date"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    {...field}
                    type="date"
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="endDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    margin="dense"
                    id="endDate"
                    label="End Date"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    {...field}
                    type="date"
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="notes"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    margin="dense"
                    id="notes"
                    label="Notes (Additional Info)"
                    fullWidth
                    multiline
                    minRows={3}
                    variant="standard"
                    {...field}
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
