import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { useCallback } from "react";
import { db } from "../firebase";
import { Lead, setClients, setLeads } from "../store/data.store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setDeleteDialog, setShowBackdrop } from "../store/ui.store";
import useError from "./useError";

const useLeads = (type: "Lead" | "Client") => {
  const dispatch = useAppDispatch();
  const handleError = useError();

  const { leads, clients, user } = useAppSelector((state) => state.data);

  const getLeadByMobile = async (mobile: string) => {
    try {
      const path = `/tenants/${user?.tenantId}/leads`;
      const q = query(
        collection(db, path),
        where("mobile", "==", mobile),
        limit(1)
      );
      const snap = await getDocs(q);
      return snap.docs.length === 0
        ? undefined
        : ({ ...snap.docs[0].data(), docId: snap.docs[0].id } as Lead);
    } catch (error) {
      handleError(error);
      return undefined;
    }
  };

  const addLead = async (data: Lead) => {
    dispatch(setShowBackdrop(true));
    try {
      if (await getLeadByMobile(data.mobile)) {
        console.log("Lead already exist");
        return;
      }
      const path = `/tenants/${user?.tenantId}/leads`;

      const result = await addDoc(collection(db, path), {
        ...data,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      if (type === "Lead") {
        dispatch(setLeads([...leads, { ...data, docId: result.id }]));
      }
      if (type === "Client") {
        dispatch(setClients([...clients, { ...data, docId: result.id }]));
      }
      return true;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const updateLead = async (docId: string, data: Partial<Lead>) => {
    dispatch(setShowBackdrop(true));
    try {
      await updateDoc(doc(db, `/tenants/${user?.tenantId}/leads/${docId}`), {
        ...data,
        updatedAt: serverTimestamp(),
      });

      if (type === "Lead") {
        const updated = leads.map((e) =>
          e.docId === docId ? { ...e, ...data } : e
        );
        dispatch(setLeads(updated));
      }
      if (type === "Client") {
        const updated = clients.map((e) =>
          e.docId === docId ? { ...e, ...data } : e
        );
        dispatch(setClients(updated));
      }
      return true;
    } catch (error) {
      handleError(error);
      return false;
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const getLeads = useCallback(async () => {
    if (type === "Lead" && leads.length !== 0) return;
    if (type === "Client" && clients.length !== 0) return;

    dispatch(setShowBackdrop(true));
    try {
      const path = `/tenants/${user?.tenantId}/leads`;

      const q = query(
        collection(db, path),
        where("type", "==", type),
        orderBy("createdAt", "desc")
      );
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map(
        (e) => ({ ...e.data(), docId: e.id } as Lead)
      );
      if (type === "Lead") dispatch(setLeads(data));
      if (type === "Client") dispatch(setClients(data));
    } catch (error) {
      handleError(error);
    } finally {
      dispatch(setShowBackdrop(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients.length, dispatch, leads.length, type, user?.tenantId]);

  const deleteLead = async (docId: string) => {
    dispatch(setShowBackdrop(true));
    try {
      await deleteDoc(doc(db, `/tenants/${user?.tenantId}/leads/${docId}`));

      dispatch(setDeleteDialog({ show: false }));
      if (type === "Lead") {
        dispatch(setLeads(leads.filter((e) => e.docId !== docId)));
      }
      if (type === "Client") {
        dispatch(setClients(clients.filter((e) => e.docId !== docId)));
      }
    } catch (error) {
      handleError(error);
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  return { addLead, updateLead, getLeads, deleteLead, getLeadByMobile };
};
export default useLeads;
