import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  MenuItem,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import InputField from "../inputField";
import useProfile from "../../hooks/useProfile";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface AddTeamMemberDialogProps {
  open?: boolean;
  onClose: () => void;
}

interface Form {
  email: string;
  role: string;
  password: string;
}

const AddTeamMemberDialog = ({ open, onClose }: AddTeamMemberDialogProps) => {
  const { inviteTeamMember } = useProfile();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Form>();

  const [showPassword, setShowPassword] = useState(false);

  const onSubmit: SubmitHandler<Form> = async (data) => {
    const res = await inviteTeamMember(data.email, data.role, data.password);
    if (res) dialogClose();
  };

  const dialogClose = () => {
    reset();
    onClose();
  };

  return !open ? null : (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle>Add Team Member</DialogTitle>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <InputField
            id="email"
            label="Email"
            fullWidth
            {...register("email", { required: true })}
            required
            error={!!errors.email}
          />
          <Box sx={{ my: 2 }}></Box>
          <InputField
            id="role"
            label="Role"
            fullWidth
            {...register("role", { required: true })}
            required
            error={!!errors.role}
            select
          >
            {["Admin", "Member"].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </InputField>
          <Box sx={{ my: 1 }}></Box>
          <InputField
            id="password"
            label="Password"
            margin="dense"
            fullWidth
            required
            {...register("password", { required: true })}
            error={!!errors.role}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((prev) => !prev)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddTeamMemberDialog;
