import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Lead } from "../../store/data.store";

interface AddClientDialogProps {
  open?: boolean;
  onClose: () => void;
  client?: Lead;
}

interface ClientForm {
  name: string;
  mobile: string;
  email?: string;
  address: string;
}

export default function AddClientDialog({
  open,
  onClose,
  client,
}: AddClientDialogProps) {
  const { control, handleSubmit } = useForm<ClientForm>();

  const onSubmit: SubmitHandler<ClientForm> = (data) => {
    console.log(data);
  };

  return !open ? null : (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add Client</DialogTitle>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                autoFocus
                required
                margin="dense"
                id="name"
                label="Name"
                fullWidth
                variant="standard"
                {...field}
                error={!!error}
                helperText={!!error && "This field is required!"}
              />
            )}
          />
          <Controller
            name="mobile"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="mobile"
                label="Mobile Number"
                type="tel"
                required
                margin="dense"
                fullWidth
                variant="standard"
                {...field}
                error={!!error}
                helperText={!!error && "This field is required!"}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="email"
                label="Email"
                type="email"
                margin="dense"
                fullWidth
                variant="standard"
                {...field}
                error={!!error}
              />
            )}
          />

          <Controller
            name="address"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                id="address"
                label="Address"
                required
                margin="dense"
                fullWidth
                variant="standard"
                {...field}
                error={!!error}
                helperText={!!error && "This field is required!"}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
