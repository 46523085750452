import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface ICatalogue {
  docId?: string;
  itemName: string;
  manualItemName?: string;
  description: string;
  image?: { url: string; type: string; path: string };
  file?: File;
  active: boolean;
}

export interface CatalogueState {
  catalogue: ICatalogue[];
}

const initialState: CatalogueState = {
  catalogue: [],
};

export const catalogueSlice = createSlice({
  name: "catalogue",
  initialState,
  reducers: {
    setCatalogue(state, action: PayloadAction<CatalogueState["catalogue"]>) {
      state.catalogue = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCatalogue } = catalogueSlice.actions;

export default catalogueSlice.reducer;
