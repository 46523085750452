import styled from "@emotion/styled";
import { CloudUpload } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { useForm } from "react-hook-form";
import InputField from "../../components/inputField";
import useCatalogue from "../../hooks/useCatalogue";
import { ICatalogue } from "../../store/catalogue.store";

interface AddCatalogueDialogProps {
  open?: boolean;
  onClose: () => void;
  catalogue?: ICatalogue;
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function AddCatalogueDialog({
  open,
  onClose,
  catalogue,
}: AddCatalogueDialogProps) {
  const { addItem } = useCatalogue();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ICatalogue>();

  const [file, setFile] = useState<File | undefined>();

  const onSubmit = async (data: ICatalogue) => {
    await addItem({ ...data, file: file });
    reset();
    onClose();
  };

  return !open ? null : (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add Catalogue</DialogTitle>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <InputField
            id="itemName"
            label="Item Name"
            fullWidth
            {...register("itemName", { required: true })}
            required
            error={!!errors.itemName}
          />
          <div className="my-2"></div>
          <InputField
            id="description"
            fullWidth
            label="Description"
            {...register("description")}
            error={!!errors.description}
          />
          <div className="my-4"></div>
          <Button
            component="label"
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            startIcon={<CloudUpload />}
          >
            Upload file
            <VisuallyHiddenInput
              type="file"
              onChange={(e) => setFile(e?.target?.files?.[0])}
            />
          </Button>
          {file && (
            <>
              <div className="font-semibold text-accent4">Image Preview:</div>
              <img
                className="max-h-60 object-contain"
                src={URL.createObjectURL(file)}
                alt="item images"
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
