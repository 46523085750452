import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { ReactNode, SyntheticEvent, useEffect, useState } from "react";
import useProjects from "../../hooks/useProjects";
import { Project } from "../../store/data.store";
import { dateTimeFormat } from "../../utils/constants";

interface ProjectInfoDialogProps {
  docId?: string;
  onClose: () => void;
}

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(name: string) {
  return {
    id: `tab-${name}`,
    "aria-controls": `tabpanel-${name}`,
  };
}

const ProjectInfoDialog = ({ docId, onClose }: ProjectInfoDialogProps) => {
  const { getLeadProjects } = useProjects();
  const [projects, setProjects] = useState<Project[]>([]);

  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (docId) {
      getLeadProjects(docId).then((data) => setProjects(data));
    }
  }, [docId, getLeadProjects]);

  return !docId ? null : (
    <Dialog open={!!docId} maxWidth="lg" fullWidth>
      <DialogTitle>Project Details</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="project tabs"
            >
              {projects.map((project) => (
                <Tab
                  key={project.docId}
                  label={project.type}
                  {...a11yProps(project.name)}
                  sx={{ fontWeight: "600" }}
                />
              ))}
            </Tabs>
          </Box>
          {projects.map((project, index) => (
            <CustomTabPanel key={project.docId} value={value} index={index}>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <div className="flex flex-col gap-5">
                    <div className="text-primary font-semibold">
                      Project Name:{" "}
                      <span className="text-black">{project.name}</span>
                    </div>
                    <div className="text-primary font-semibold">
                      Project Type:{" "}
                      <span className="text-black">{project.type}</span>
                    </div>

                    <div className="text-primary font-semibold">
                      Start Date:{" "}
                      <span className="text-black">{project.startDate}</span>
                    </div>
                    <div className="text-primary font-semibold">
                      End Date:{" "}
                      <span className="text-black">{project.endDate}</span>
                    </div>
                    <div className="text-primary font-semibold">
                      Address:{" "}
                      <span className="text-black">{`${
                        project.address.line1
                      }, ${project.address.line2 ?? ""}, ${
                        project.address.city
                      }, ${project.address.state}, ${
                        project.address.pincode
                      }`}</span>
                    </div>
                    <div className="text-primary font-semibold">
                      Notes: <span className="text-black">{project.notes}</span>
                    </div>
                    <div className="text-primary font-semibold">
                      Created On:{" "}
                      <span className="text-black">
                        {dateTimeFormat.format(project.createdAt.toDate())}
                      </span>
                    </div>
                  </div>
                </Grid>
                <Grid item sm={6}>
                  <div className="flex flex-col gap-5">
                    <div className="text-primary font-semibold">
                      Built-Up Area:{" "}
                      <span className="text-black">
                        {project.area?.value} {project.area?.units}
                      </span>
                    </div>
                    <div className="text-primary font-semibold">
                      BHK: <span className="text-black">{project.bhk}</span>
                    </div>
                    <div className="text-primary font-semibold">
                      Client Budget:{" "}
                      <span className="text-black">{project.clientBudget}</span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </CustomTabPanel>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectInfoDialog;
