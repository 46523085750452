import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import useLeads from "../../hooks/useLeads";
import { Lead } from "../../store/data.store";
import {
  LEAD_STATUS,
  SOURCE_TYPE,
  serializeObject,
} from "../../utils/constants";

interface AddLeadDialogProps {
  open?: boolean;
  onClose: () => void;
  lead?: Lead;
}

export default function AddLeadDialog({
  open,
  onClose,
  lead,
}: AddLeadDialogProps) {
  const { control, handleSubmit, reset, setValue } = useForm<Lead>();

  const { addLead, updateLead } = useLeads("Lead");

  const onSubmit: SubmitHandler<Lead> = async (data) => {
    const serializedData = serializeObject(data);
    const result = lead
      ? await updateLead(lead.docId!, serializedData)
      : await addLead({
          ...serializedData,
          status: LEAD_STATUS[0],
          type: "Lead",
        });
    if (result) {
      dialogClose();
    }
  };

  const dialogClose = () => {
    reset();
    onClose();
  };

  useEffect(() => {
    if (lead) {
      setValue("name", lead.name);
      setValue("mobile", lead.mobile);
      setValue("email", lead.email);
      setValue("company", lead.company);
      setValue("address", lead.address);
      setValue("source", lead.source);
      setValue("status", lead.status);
    }
  }, [lead, setValue]);

  return !open ? null : (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>{lead ? "Update" : "Add"} Lead</DialogTitle>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Grid container columnSpacing={4}>
            <Grid item xs={6}>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    label="Name"
                    fullWidth
                    variant="standard"
                    {...field}
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="mobile"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="mobile"
                    label="Mobile Number"
                    type="tel"
                    required
                    margin="dense"
                    fullWidth
                    variant="standard"
                    {...field}
                    error={!!error}
                    helperText={!!error && "Invalid Mobile Number"}
                    inputProps={{ maxLength: 10 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+91</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="email"
                    label="Email"
                    type="email"
                    margin="dense"
                    fullWidth
                    variant="standard"
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="company"
                control={control}
                rules={{ required: false }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="company"
                    label="Company Name"
                    margin="dense"
                    fullWidth
                    variant="standard"
                    {...field}
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="address.line1"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="address.line1"
                    label="Address Line 1"
                    required
                    margin="dense"
                    fullWidth
                    variant="standard"
                    {...field}
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="address.line2"
                control={control}
                rules={{ required: false }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="address.line2"
                    label="Address Line 2"
                    margin="dense"
                    fullWidth
                    variant="standard"
                    {...field}
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="address.city"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="address.city"
                    label="City"
                    required
                    margin="dense"
                    fullWidth
                    variant="standard"
                    {...field}
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="address.state"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="address.state"
                    label="State"
                    required
                    margin="dense"
                    fullWidth
                    variant="standard"
                    {...field}
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="address.pincode"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="address.pincode"
                    label="Pincode"
                    required
                    margin="dense"
                    fullWidth
                    variant="standard"
                    {...field}
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                    inputProps={{
                      maxLength: 6,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="source"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="source"
                    label="Lead Source"
                    required
                    margin="dense"
                    select
                    fullWidth
                    variant="standard"
                    defaultValue=""
                    {...field}
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                  >
                    {SOURCE_TYPE.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <Controller
                name="status"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    id="status"
                    label="Lead Status"
                    required
                    margin="dense"
                    select
                    fullWidth
                    variant="standard"
                    defaultValue=""
                    {...field}
                    error={!!error}
                    helperText={!!error && "This field is required!"}
                  >
                    {LEAD_STATUS.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
