import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import { useCallback } from "react";
import { db } from "../firebase";
import { ICatalogue, setCatalogue } from "../store/catalogue.store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setDeleteDialog, setShowBackdrop } from "../store/ui.store";
import useFileUpload from "./useFileUpload";
import useError from "./useError";

const useCatalogue = () => {
  const { uploadFile, deleteFile } = useFileUpload();
  const dispatch = useAppDispatch();
  const handleError = useError();

  const catalogue = useAppSelector((state) => state.catalogue.catalogue);
  const user = useAppSelector((state) => state.data.user);

  const getItems = useCallback(async () => {
    if (catalogue.length !== 0) return;
    dispatch(setShowBackdrop(true));
    try {
      const path = `/tenants/${user?.tenantId}/catalogue`;
      const q = query(collection(db, path), orderBy("createdAt", "asc"));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map(
        (e) => ({ ...e.data(), docId: e.id } as ICatalogue)
      );
      dispatch(setCatalogue(data));
    } catch (error) {
      handleError(error);
    } finally {
      dispatch(setShowBackdrop(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogue.length, dispatch, user?.tenantId]);

  const addItem = async (data: ICatalogue) => {
    dispatch(setShowBackdrop(true));
    try {
      const path = `/tenants/${user?.tenantId}/catalogue`;
      const { itemName, description, file } = data;

      let fileData;

      if (file) {
        fileData = await uploadFile(file, `${path}/${itemName}`);
      }
      const catalogueItem = {
        itemName,
        description: description || null,
        ...(fileData ? { image: { ...fileData } } : {}),
        active: true,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      };
      const docRef = await addDoc(collection(db, path), catalogueItem);

      Object.assign(catalogueItem, { docId: docRef.id });

      dispatch(setCatalogue([...catalogue, catalogueItem as ICatalogue]));
    } catch (error) {
      handleError(error);
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const updateItem = async (data: Partial<ICatalogue>) => {
    dispatch(setShowBackdrop(true));
    try {
      const path = `/tenants/${user?.tenantId}/catalogue/${data.docId}`;

      const catalogueItem = {
        ...data,
        updatedAt: Timestamp.now(),
      };
      await updateDoc(doc(db, path), catalogueItem);

      const updated = catalogue.map((item) =>
        item.docId === data.docId ? { ...item, catalogueItem } : item
      );

      dispatch(setCatalogue(updated));
    } catch (error) {
      handleError(error);
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  const deleteItem = async (docId: string, imagePath?: string) => {
    dispatch(setShowBackdrop(true));
    try {
      const path = `/tenants/${user?.tenantId}/catalogue`;

      await deleteDoc(doc(db, `${path}/${docId}`));
      if (imagePath) await deleteFile(imagePath);

      dispatch(setDeleteDialog({ show: false }));
      dispatch(setCatalogue(catalogue.filter((e) => e.docId !== docId)));
    } catch (error) {
      handleError(error);
    } finally {
      dispatch(setShowBackdrop(false));
    }
  };

  return { getItems, addItem, deleteItem, updateItem };
};

export default useCatalogue;
